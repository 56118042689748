import { Box, SvgIconProps, Typography } from '@mui/material';
import React from 'react';
import styles from './styles';

interface ModalHeaderProps {
  title: string;
  Icon?: (props: SvgIconProps) => JSX.Element;
}

const ModalHeader = (props: ModalHeaderProps) => {
  const { title, Icon } = props;
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        px: styles.modalPaddingX,
        py: styles.modalPaddingY,
      }}
    >
      {Icon && <Icon sx={{ fontSize: 32, mr: 3 }} />}
      <Typography variant='h5' component='h2' fontWeight={500}>
        {title}
      </Typography>
    </Box>
  );
};

export default ModalHeader;
