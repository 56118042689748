import { BufferAttribute, BufferGeometry } from 'three';
import { generateUUID } from 'three/src/math/MathUtils';
import { IParkingLotEntrance, IParkingLotPosition, XY } from '../../types';
import { getParkingLotSurfaceVertexPositions } from '../../components/Building/Face/FaceSegment/geometry';

export const getParkingLotCorners = (length: number, width: number): XY[] => {
  const halfLength = length / 2;
  const halfWidth = width / 2;
  return [
    [-halfLength, halfWidth],
    [-halfLength, -halfWidth],
    [halfLength, -halfWidth],
    [halfLength, halfWidth],
  ];
};

export const getParkingLotEntrances = (length: number, width: number, laneWidth: number): IParkingLotEntrance[] => {
  const halfLength = length / 2;
  const halfWidth = width / 2;
  return [
    {
      id: generateUUID(),
      position: [-halfLength, -halfWidth + laneWidth / 2],
      entranceDirection: [1, 0],
    },
    {
      id: generateUUID(),
      position: [halfLength, -halfWidth + laneWidth / 2],
      entranceDirection: [-1, 0],
    },
  ];
};

export const generateParkingLotGeometry = (corners: XY[], height: number) => {
  const vertexPositions = [];
  const topVertexPositions = getParkingLotSurfaceVertexPositions(corners, 0);
  vertexPositions.push(...topVertexPositions);
  const bottomVertexPositions = getParkingLotSurfaceVertexPositions(corners, -height);
  vertexPositions.push(...bottomVertexPositions);

  // add each wall as a separate set of vertexPositions
  for (let i = 0; i < corners.length; i++) {
    const nextIndex = (i + 1) % corners.length;
    // for each wall create two triangles. Each triangle has 3 vertices. put all coordinates in one flat array. use corners array as source. corners is an array of [number, number]
    const wallVertexPositions = [
      ...corners[i],
      0,
      ...corners[i],
      -height,
      ...corners[nextIndex],
      -height,
      ...corners[i],
      0,
      ...corners[nextIndex],
      -height,
      ...corners[nextIndex],
      0,
    ];
    vertexPositions.push(...wallVertexPositions);
  }

  const vertices = new Float32Array(vertexPositions);

  const geometry = new BufferGeometry();
  geometry.setAttribute('position', new BufferAttribute(vertices, 3));
  geometry.computeVertexNormals();

  return geometry;
};

export const getAbsoluteParkingLotCoordinates = (
  lotPosition: IParkingLotPosition,
  rotation: number,
  coordinates: XY,
): XY => {
  const [x, y] = coordinates;
  const cos = Math.cos(rotation);
  const sin = Math.sin(rotation);
  const rotatedX = x * cos - y * sin;
  const rotatedY = x * sin + y * cos;
  return [rotatedX + lotPosition.x, rotatedY + lotPosition.y];
};
