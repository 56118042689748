import { Box, Button, Typography } from '@mui/material';
import useShopCartTotalPrice from 'components/features/ShopModal/hooks/useShopCartTotalPrice';
import useShopData from 'components/features/ShopModal/hooks/useShopData';
import UNITS from 'constants/units';
import LocationHeader from 'pages/App/components/LocationHeader';
import useLocationData from 'pages/App/hooks/useLocationData';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FULL_SHOP_PATHS } from 'routes/routers/shop/shopPaths';
import { shopCartItemsSelector } from 'state/selectors/shop';
import { formatNumber, getPrintValue } from 'utils/formatters';

const styles = {
  header: {
    display: 'flex',
    flexDirection: {
      xs: 'column',
      sm: 'row',
    },
    justifyContent: 'space-between',
    alignItems: {
      xs: 'flex-start',
      sm: 'center',
    },
    p: 6,
  },
  checkoutWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    pl: {
      xs: 0,
      sm: 6,
    },
    pt: {
      xs: 6,
      sm: 0,
    },
    mt: {
      xs: 6,
      sm: 0,
    },
    borderTop: {
      xs: 1,
      sm: 'none',
    },
    borderColor: {
      xs: 'grey.300',
      sm: 'grey.300',
    },
    width: {
      xs: '100%',
      sm: 'auto',
    },
  },
  totalWrapper: {
    textAlign: {
      xs: 'left',
      sm: 'right',
    },
    pr: 6,
  },
};

const ShopViewHeader = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocationData();

  const { data: shopData } = useShopData();
  const cartItems = useSelector(shopCartItemsSelector);

  const totalPrice = useShopCartTotalPrice(cartItems, shopData);

  const handleContinue = () => {
    navigate(FULL_SHOP_PATHS.BILLING_DETAILS.ROOT);
  };

  return (
    <Box sx={styles.header}>
      <LocationHeader location={location} showEgrids />
      <Box sx={styles.checkoutWrapper}>
        <Box sx={styles.totalWrapper}>
          <Typography variant='h6' fontWeight={500} noWrap>
            {getPrintValue(formatNumber(totalPrice), UNITS.CURRENCY)}
          </Typography>
          <Typography variant='body2' color='textSecondary' noWrap>
            {t('billing:general.excludingVAT')}
          </Typography>
        </Box>
        <Button
          variant='contained'
          color='secondary'
          size='large'
          onClick={handleContinue}
          disabled={!cartItems.length}
          sx={{ whiteSpace: 'nowrap' }}
        >
          {t('billing:general.purchase')}
        </Button>
      </Box>
    </Box>
  );
};

export default ShopViewHeader;
