import { proxy } from 'valtio';
import { ISolarLimits, ISolarSegments, Nullable } from '../types';

interface IDataStore {
  solarSegments: Nullable<ISolarSegments>;
  solarLimits: ISolarLimits;
}

const dataStore = proxy<IDataStore>({
  solarSegments: null,
  solarLimits: {
    yearlyReduction: [0, 100],
    yearlyIrradiation: [0, 1320],
    reductionMonthlyMin: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    reductionMonthlyMax: [100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
    irradiationMonthlyMin: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    irradiationMonthlyMax: [110, 110, 110, 110, 110, 110, 110, 110, 110, 110, 110, 110],
  },
});

export default dataStore;
