import { EBufferType } from './buffers';
import { ILngLat, XY, XYZ } from './coordinates';

export enum EBuildingVariant {
  PROJECT = 'project',
  EXISTING = 'existing',
}

export enum EBuildingType {
  MULTI_FAMILY = 'multiFamily',
  SINGLE_FAMILY = 'singleFamily',
  INDUSTRIAL = 'industrial',
  OFFICE = 'office',
  MULTI_FAMILY_AND_OFFICE = 'multiFamilyAndOffice',
}

export enum EFloorShape {
  STANDARD = 'standard',
  ROOF = 'roof',
}

export enum ESurfaceType {
  WALL = 'wall',
  FLOOR = 'floor',
  ROOF = 'roof',
  CORE = 'core',
}

export enum EFloorType {
  DG = 'DG',
  VG = 'VG',
  UG = 'UG',
}

export type TFootprint = XY[];

export type TBuildings = Record<string, IBuilding>;

export interface IBuilding {
  id: string;
  copyId?: string;
  floors: TFloors;
  construction: IConstruction;
  services: IBuildingServices;
  properties: IBuildingProperties;
  buildingFormChanged?: boolean;
}

export interface IConstruction {
  wallThickness: number;
  ceilingThickness: number;
  roofThickness: number;
  nrOfCores: number;
  staircaseType: string;
  materials: Record<EBuildingPartWithoutWallAndWindow, string>;
}

export interface IBuildingServices {
  pvIsIntegrated: boolean;
  pvEfficiency: number;
  pvEfficiencyError: number;
  pvSubsidy: number;
  energySelfConsumptionRate: number;
  energyFeedInRate: number;
  energySystemType: EBuildingEnergySystemType;
  energyConsumptionProfile: EBuildingEnergyConsumptionProfile;
  hasBattery: boolean;
}

export enum EBuildingEnergyConsumptionProfile {
  RESIDENTIAL = 'residential',
  COMMERCIAL = 'commercial',
}

export enum EBuildingEnergySystemType {
  ELECTRICITY = 'electricity',
  ELECTRICITY_AND_HEATING = 'electricityAndHeating',
  ELECTRICITY_AND_WATER = 'electricityAndWater',
}

export interface ICoreType {
  area: number;
  length: number;
}

export interface IBuildingProperties {
  name?: string;
  type: EBuildingType;
  variant: EBuildingVariant;
  nrOfFloors?: number;
  height?: number;
  buffers: IBufferObject[];
}

export interface IBuildingWall {
  id: string;
  direction: number;
  directionName: EFaceDirection;
  faces: TFace[];
  segmentIds: string[];
  openingsOnWall: ESegmentOpening[];
  selectedSegmentCount: number;
  totalSegmentCount: number;
}

export interface IFloorFacade {
  floorId: string;
  selectedSegmentCount: number;
  totalSegmentCount: number;
  floorNumber: number;
  floorType: EFloorType;
  openingsOnFloor: ESegmentOpening[];
  facadeFaces: IFacadeFace[];
  segmentIds: string[];
}

export interface IFacadeFace {
  totalSegmentsCount: number;
  selectedSegmentsCount: number;
  direction: number;
  directionName: EFaceDirection;
  face: TFace;
  openingsOnFacade: ESegmentOpening[];
  segmentIds: string[];
}

export interface IFacadeVariant {
  opening: ESegmentOpening;
  selectedSegmentCount: number;
  totalSegmentCount: number;
  wallLayoutGroups: IWallLayoutGroup[];
  segmentIds: string[];
}

export interface IWallLayoutGroup {
  wallLayout: EWallLayout;
  selectedSegmentCount: number;
  totalSegmentCount: number;
  segmentIds: string[];
}

export type TFloors = Record<string, IFloor>;

export interface IFloor {
  id: string;
  faces: TFace[];
  usage?: IFloorUsage;
  usageType?: EFloorUsageType;
  properties: IFloorProperties;
  copyId?: string;
}

export interface IFloorUsage {
  id: string;
  sideUsageRatio: number;
  multiFamilyRatio: number;
  multiFamilySaleIncome: number;
  multiFamilyRentIncome: number;
  singleFamilyRatio: number;
  singleFamilySaleIncome: number;
  singleFamilyRentIncome: number;
  businessRatio: number;
  businessSaleIncome: number;
  businessRentIncome: number;
}

export enum EFloorUsageType {
  GENERAL = 'general',
  UNITS = 'units',
}

export interface IFloorProperties {
  order: number;
  height: number;
  z: number;
  shape: EFloorShape;
  type: EFloorType;
  corridorArea: number;
  corridorWidth: number;
  color?: string;
  opacity?: number;
  baseHeight?: number;
  livingHeight?: number;
}

export interface IFaceSegment {
  id: string;
  faceId: string;
  properties: IFaceSegmentProperties;
  corners: ISegmentPoint[];
}

export type TMonthlyNumberValues = [
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
  number,
];

export enum ESegmentOpening {
  WINDOW = 'window',
  DOOR = 'door',
  NONE = 'none',
}

export interface IFaceSegmentProperties {
  segmentWidth: number;
  segmentHeight: number;
  wallLayout: EWallLayout;
  wallProperties: ISegmentWallProperties;
  pvActive: boolean;
  balconyDividers: EBalconyDividers;
  hasBalcony: boolean;
  opening: ESegmentOpening;
}

export enum EBalconyDividers {
  LEFT = 'left',
  RIGHT = 'right',
  BOTH = 'both',
  NONE = 'none',
}

export enum EPVActivationState {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  MIXED = 'mixed',
}

export interface ISelectedFacadeProperties {
  wallLayouts: EWallLayout[];
  hasBalcony: boolean | null;
  balconyDividers: EBalconyDividers | null;
  openings: ESegmentOpening[];
  wallMaterials: TSegmentWallPartMaterials;
  topHeights: number[];
  bottomHeights: number[];
  sidesWidths: number[];
  // pvIsActive: EPVActivationState;
}

export interface IWallPartProperties {
  width: number;
  height: number;
  color: string;
  hasPV: boolean;
}

export interface IWallTopProperties extends IWallPartProperties {
  variableHeight: number;
}

export interface ISegmentWallProperties {
  top: IWallTopProperties;
  bottom: IWallPartProperties;
  sides: IWallPartProperties;
  center: IWallPartProperties;
}

export type TSegmentWallPartMaterials = Record<ESegmentWallPart, string[]>;

export enum ESegmentWallPart {
  TOP = 'top',
  BOTTOM = 'bottom',
  SIDES = 'sides',
  CENTER = 'center',
}

export enum ESegmentTopHeight {
  ZERO = 0,
  FIFTEEN = 0.15,
  THIRTY = 0.3,
  FIFTY = 0.5,
  EIGHTY = 0.8,
}

export enum ESegmentBottomHeight {
  ZERO = 0,
  SEVENTY = 0.7,
  EIGHTY_FIVE = 0.85,
  HUNDRED = 1,
}

export enum ESegmentSideWidth {
  ZERO = 0,
  FIFTEEN = 0.15,
  THIRTY = 0.3,
  FIFTY = 0.5,
  SEVENTY_FIVE = 0.75,
  HUNDRED = 1,
}

export enum EWallLayout {
  NARROW_TOP_AND_BOTTOM = 'narrowTopAndBottom',
  WIDE_TOP_AND_BOTTOM = 'wideTopAndBottom',
  WIDE_TOP = 'wideTop',
  WIDE_BOTTOM = 'wideBottom',
  NO_BOTTOM_NARROW_TOP = 'noBottomNarrowTop',
  NO_BOTTOM_WIDE_TOP = 'noBottomWideTop',
}

export enum ESegmentMaterialType {
  WINDOW = 'window',
  WALL = 'wall',
  PV = 'pv',
}

// export enum EBuildingMaterialId {
//   STONE_GRAY = 'stoneGray',
//   MOSS_GRAY = 'mossGray',
//   TRAFFIC_WHITE = 'trafficWhite',
//   PV_TRAFFIC_WHITE = 'pvTrafficWhite',
//   GRAY_LIGHT = 'grayLight',
//   AGATE_GRAY = 'agateGray',
//   PEARL_MOUSE_GRAY = 'pearlMouseGray',
//   PV_PEARL_MOUSE_GRAY = 'pvPearlMouseGray',
//   GRAY_BROWN = 'grayBrown',
//   PV_GRAY_BROWN = 'pvGrayBrown',
//   BEIGE_RED = 'beigeRed',
//   OXIDE_RED = 'oxideRed',
//   PV_OXIDE_RED = 'pvOxideRed',
//   OYSTER_WHITE = 'oysterWhite',
//   GLASS = 'glass',
//   SAND_YELLOW = 'sandYellow',
//   PALE_BROWN = 'paleBrown',
//   PV_PALE_BROWN = 'pvPaleBrown',
//   SLATE_GRAY = 'slateGray',
// }

// export interface IBuildingMaterial {
//   id: EBuildingMaterialId | string;
//   type: ESegmentMaterialType;
//   hex: string;
//   suitableBuildingParts: Record<EBuildingPart, boolean>;
// }

export enum EBuildingPart {
  FLAT_ROOF_FLOOR = 'flatRoofFloor',
  ROOF_EDGE = 'roofEdge',
  GABLED_ROOF = 'gabledRoof',
  TERRACE_FLOOR = 'terraceFloor',
  BALCONY_FLOOR = 'balconyFloor',
  TERRACE_RAILING = 'terraceRailing',
  BALCONY_RAILING = 'balconyRailing',
  WINDOW_RAILING = 'windowRailing',
  WINDOW = 'window',
  WALL = 'wall',
}

export enum EBuildingPartWithoutWallAndWindow {
  FLAT_ROOF_FLOOR = 'flatRoofFloor',
  ROOF_EDGE = 'roofEdge',
  GABLED_ROOF = 'gabledRoof',
  TERRACE_FLOOR = 'terraceFloor',
  BALCONY_FLOOR = 'balconyFloor',
  TERRACE_RAILING = 'terraceRailing',
  BALCONY_RAILING = 'balconyRailing',
  WINDOW_RAILING = 'windowRailing',
}

export interface ISolarLimits {
  yearlyReduction: [number, number];
  yearlyIrradiation: [number, number];
  reductionMonthlyMin: TMonthlyNumberValues;
  reductionMonthlyMax: TMonthlyNumberValues;
  irradiationMonthlyMin: TMonthlyNumberValues;
  irradiationMonthlyMax: TMonthlyNumberValues;
}

export type ISolarSegments = Record<string, ISolarSegment>;

export interface ISolarSegment {
  yearlyIrradiation: number;
  yearlyIrradiationReduction: number;
  monthlyIrradiation: TMonthlyNumberValues;
  monthlyIrradiationReduction: TMonthlyNumberValues;
}

export interface ISegmentPoint {
  coordinates: XYZ;
  order?: number;
  id?: string;
  meta?: IMeta;
}

export interface IMeta {
  createdAt: string;
  updatedAt: string;
}

export interface IBaseFace {
  id: string;
  coordinates: XYZ[];
  segments: IFaceSegment[];
}

export interface IOtherFace extends IBaseFace {
  type: EFaceType.FLOOR | EFaceType.ROOF | EFaceType.CEILING;
}

export interface IWallFace extends IBaseFace {
  order: number;
  type: EFaceType.WALL;
}

export type TFace = IWallFace | IOtherFace;

export enum EFaceDirection {
  NORTH = 'N',
  NORTH_EAST = 'NE',
  EAST = 'E',
  SOUTH_EAST = 'SE',
  SOUTH = 'S',
  SOUTH_WEST = 'SW',
  WEST = 'W',
  NORTH_WEST = 'NW',
}

export enum WallMaterial {
  CONCRETE = 'concrete',
  WOOD = 'wood',
  MIXED = 'mixed',
}

export enum EFaceType {
  FLOOR = 'floor',
  CEILING = 'ceiling',
  WALL = 'wall',
  ROOF = 'roof',
}

export enum EExistingBuildingColor {
  SURFACE = '#cecece',
  SURFACE_SELECTED = '#cecece',
  SURFACE_DISABLED = '#cecece',
  FACADE = '#cecece',
}

export interface IAddBuildingOptions {
  center: ILngLat;
  plotGeometry: ILngLat[];
}

export enum ECardinalDirectionBounds {
  EAST_LOWER = 45,
  EAST_UPPER = 135,
  SOUTH_LOWER = 135,
  SOUTH_UPPER = 225,
  WEST_LOWER = 225,
  WEST_UPPER = 315,
}

export enum ECardinalDirections {
  NORTH = 'north',
  EAST = 'east',
  SOUTH = 'south',
  WEST = 'west',
}

export interface IBufferObject {
  id: string;
  geometry: [number, number][];
  order: number;
  bufferType: EBufferType;
  bufferDistance: number;
}

export interface IBufferLineSegment {
  order: number;
  coordinates: XY[];
}

export interface IDirectionRadiation {
  direction: number;
  angle: number;
  radiation: number;
}

export enum EBuildingFacadePreset {
  MULTI_FAMILY_1 = 'multiFamily1',
  MULTI_FAMILY_2 = 'multiFamily2',
  MULTI_FAMILY_3 = 'multiFamily3',
  MULTI_FAMILY_4 = 'multiFamily4',
  MULTI_FAMILY_5 = 'multiFamily5',
  MULTI_FAMILY_6 = 'multiFamily6',
  MULTI_FAMILY_7 = 'multiFamily7',
  MULTI_FAMILY_8 = 'multiFamily8',
  COMMERCIAL_INDUSTRIAL_1 = 'commercialIndustrial1',
  COMMERCIAL_INDUSTRIAL_2 = 'commercialIndustrial2',
  COMMERCIAL_INDUSTRIAL_3 = 'commercialIndustrial3',
  COMMERCIAL_INDUSTRIAL_4 = 'commercialIndustrial4',
  COMMERCIAL_INDUSTRIAL_5 = 'commercialIndustrial5',
  COMMERCIAL_INDUSTRIAL_6 = 'commercialIndustrial6',
  COMMERCIAL_INDUSTRIAL_7 = 'commercialIndustrial7',
  COMMERCIAL_INDUSTRIAL_8 = 'commercialIndustrial8',
  SINGLE_FAMILY_1 = 'singleFamily1',
  SINGLE_FAMILY_2 = 'singleFamily2',
  SINGLE_FAMILY_3 = 'singleFamily3',
  SINGLE_FAMILY_4 = 'singleFamily4',
  SINGLE_FAMILY_5 = 'singleFamily5',
  SINGLE_FAMILY_6 = 'singleFamily6',
  SINGLE_FAMILY_7 = 'singleFamily7',
  SINGLE_FAMILY_8 = 'singleFamily8',
  MULTI_FAMILY_AND_OFFICE_1 = 'multiFamilyAndOffice1',
  MULTI_FAMILY_AND_OFFICE_2 = 'multiFamilyAndOffice2',
  MULTI_FAMILY_AND_OFFICE_3 = 'multiFamilyAndOffice3',
  MULTI_FAMILY_AND_OFFICE_4 = 'multiFamilyAndOffice4',
  MULTI_FAMILY_AND_OFFICE_5 = 'multiFamilyAndOffice5',
  MULTI_FAMILY_AND_OFFICE_6 = 'multiFamilyAndOffice6',
  MULTI_FAMILY_AND_OFFICE_7 = 'multiFamilyAndOffice7',
  MULTI_FAMILY_AND_OFFICE_8 = 'multiFamilyAndOffice8',
}
