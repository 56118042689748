export type Nullable<T> = T | undefined | null;

export enum ELoadingState {
  LOADING = 'loading',
  SUCCESS = 'success',
  ERROR = 'error',
}

export enum EObjectName {
  ACTIVE_VEGETATION = 'activeVegetation',
  RESTORABLE_VEGETATION = 'restorableVegetation',
  TEMPORARY_VEGETATION = 'temporaryVegetation',
  TERRAIN_SURFACE = 'terrainSurface',
  GROUND_BOTTOM = 'groundBottom',
  PARKING_GARAGE = 'parkingGarage',
  EXISTING_BUILDING = 'existingBuilding',
  OFFSET_TERRAIN = 'offsetTerrain',
}
