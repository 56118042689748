import useLocationData from 'pages/App/hooks/useLocationData';
import { useGetShopQueryState } from 'state/services/backend/endpoints/shop/shop';

const useShopData = () => {
  const location = useLocationData();
  const { egrids = [] } = location ?? {};

  return useGetShopQueryState({
    plots: egrids.map((egrid) => ({ egrid })),
  });
};

export default useShopData;
