import * as THREE from 'three';
import { proxy, ref } from 'valtio';
import { derive } from 'valtio/utils';
import {
  EBufferType,
  ECardinalDirections,
  ETerrainWidth,
  IBufferConstructionSettings,
  IDirectionRadiation,
  ILngLat,
  Nullable,
  TBuildings,
  TTerrain,
} from '../types';
import AnimatedValue from '../utils/AnimatedValue';
import { EVegetationType } from './landscapeStore';

export enum EApplicationStep {
  LOCATION = 'location',
  DESIGN = 'design',
  LAW = 'law',
  SUMMARY = 'summary',
}

export enum EApplicationTopic {
  FORM = 'form',
  FACADE = 'facade',
  SERVICES = 'services',
  LANDSCAPE = 'landscape',
  USAGE = 'usage',
  PARKING_LOTS = 'parkingLots',
  DEFAULT = 'default',
}

export enum EApplicationMode {
  VIEW = 'view',
  EDIT = 'edit',
  BUFFER = 'bufferEdit',
}

export enum EApplicationTool {
  SELECT = 'selectBuilding',
  MOVE = 'moveBuilding',
  MODIFY = 'modifyBuilding',
  BUILDING = 'buildingFacade',
  FLOOR = 'floor',
  WALL = 'wallFacade',
  FLOOR_WALL = 'floorWall',
  SEGMENT = 'segmentFacade',
  DEFAULT = 'default',
  VEGETATION_SELECT = 'vegetationSelect',
  VEGETATION_ADD = 'vegetationAdd',
  VEGETATION_RESTORE = 'vegetationRestore',
}

export enum EObjectType {
  BUILDING = 'building',
  FLOOR = 'floor',
  SEGMENT = 'segment',
  TRANSFORM_WALL = 'transformWall',
  TRANSFORM_CORNER = 'transformCorner',
  BUFFER = 'buffer',
  PARKING_LOT = 'parkingLot',
  VEGETATION = 'vegetation',
}

export enum ESolarExposureType {
  IRRADIATION = 'irradiation',
  SHADING_FACTOR = 'shadingFactor',
}

export enum ESolarExposureInterval {
  YEAR = 'year',
  MONTH = 'month',
}

export interface IApplicationPath {
  step: EApplicationStep;
  topic: EApplicationTopic;
  mode: EApplicationMode;
  tool: EApplicationTool;
  selectionFilter: ESelectionFilter;
}

export enum ESelectionFilter {
  NONE = 'none',
  FACADE_WALL_LAYOUT = 'facadeWallLayout',
}

export interface ICityViewGeneral {
  disableClickInteraction: boolean;
  isDesktop: boolean;
  applicationPath: IApplicationPath;
  center: ILngLat;
}

export interface ICityViewBuffers {
  bufferConstructionSettings: Nullable<IBufferConstructionSettings>;
}

export interface ICityViewMouse {
  isPersistingChanges: boolean;
  isMouseDown: boolean;
  isMouseEditing: boolean;
  isMouseOnGizmoDown: boolean;
  isTransformingCamera: boolean;
}

export interface ISelectedCorner {
  buildingId: string;
  floorId: string;
  wallId: string;
  cornerId: string;
}

export interface ITexts {
  solarTooltipTitle: {
    [ESolarExposureType.IRRADIATION]: {
      [ESolarExposureInterval.YEAR]: string;
      [ESolarExposureInterval.MONTH]: string;
    };
    [ESolarExposureType.SHADING_FACTOR]: {
      [ESolarExposureInterval.YEAR]: string;
      [ESolarExposureInterval.MONTH]: string;
    };
  };
  solarTooltipUnit: {
    [ESolarExposureType.IRRADIATION]: string;
    [ESolarExposureType.SHADING_FACTOR]: string;
  };
  usageTooltipTitle: {
    livingSpaceRatio: string;
    businessSpaceRatio: string;
    sideUsageSpaceRatio: string;
  };
}

export type IBuffers = Record<ECardinalDirections, EBufferType>;

export interface IRadiation {
  data: IDirectionRadiation[];
  active: boolean;
}

export interface ICityViewSettings {
  terrain: {
    width: ETerrainWidth;
    showTerrain: boolean;
  };
  clipping: {
    active: boolean;
    constant: number;
    angle: number;
  };
  wallSnapping: {
    active: boolean;
    threshold: number;
  };
  cornerSnapping: {
    active: boolean;
    threshold: number;
  };
  solarExposure: {
    active: boolean;
    type: ESolarExposureType;
    interval: ESolarExposureInterval;
    intervalMonth: number;
  };
  offsetTerrain: {
    active: boolean;
    heightFromTerrain: number | null;
  };
}

interface IStoreState {
  general: ICityViewGeneral;
  mouse: ICityViewMouse;
  existingBuildings: TBuildings;
  animations: Record<string, AnimatedValue>;
  selections: {
    selectedObjects: Record<string, EObjectType>;
  };
  radiation: IRadiation;
  buffers: ICityViewBuffers;
  texts: ITexts;
  landscape: {
    addVegetationType: EVegetationType;
  };
  settings: ICityViewSettings;
  terrainGeometry: {
    id?: string;
    bottom?: THREE.BufferGeometry;
    overlay?: THREE.BufferGeometry;
    terrainData?: TTerrain;
  };
}

export const initialStoreState: IStoreState = {
  general: {
    disableClickInteraction: false,
    isDesktop: true,
    applicationPath: {
      step: EApplicationStep.LOCATION,
      topic: EApplicationTopic.DEFAULT,
      mode: EApplicationMode.VIEW,
      tool: EApplicationTool.DEFAULT,
      selectionFilter: ESelectionFilter.NONE,
    },
    center: {
      lng: 8.52193,
      lat: 47.3867,
    },
  },
  settings: {
    terrain: {
      width: ETerrainWidth.SMALL,
      showTerrain: true,
    },
    clipping: {
      active: false,
      constant: 0,
      angle: 0,
    },
    wallSnapping: {
      active: true,
      threshold: 0.7,
    },
    cornerSnapping: {
      active: true,
      threshold: 0.7,
    },
    solarExposure: {
      active: false,
      type: ESolarExposureType.IRRADIATION,
      interval: ESolarExposureInterval.YEAR,
      intervalMonth: 6,
    },
    offsetTerrain: {
      active: false,
      heightFromTerrain: null,
    },
  },
  animations: {
    daylightMonth: ref(
      new AnimatedValue(6, {
        moveConfig: {
          inertia: 20,
          target: 6,
        },
      }),
    ),
    daylightMinutes: ref(
      new AnimatedValue(720, {
        tweenConfig: {
          duration: 5000,
          from: 540,
          to: 1260,
          loop: true,
          easing: 'inOutQuad',
        },
      }),
    ),
    cameraAngle: ref(
      new AnimatedValue(-Math.PI / 4, {
        moveConfig: {
          inertia: 100,
          target: -Math.PI / 4,
        },
      }),
    ),
    cameraElevation: ref(new AnimatedValue(200)),
    cameraRadius: ref(new AnimatedValue(450)),
  },
  selections: {
    selectedObjects: {},
  },
  mouse: {
    isPersistingChanges: false,
    isMouseDown: false,
    isMouseEditing: false,
    isMouseOnGizmoDown: false,
    isTransformingCamera: false,
  },
  buffers: {
    bufferConstructionSettings: null,
  },
  existingBuildings: {},
  radiation: {
    data: [],
    active: false,
  },
  texts: {
    solarTooltipTitle: {
      [ESolarExposureType.IRRADIATION]: {
        [ESolarExposureInterval.YEAR]: 'Yearly irradiation',
        [ESolarExposureInterval.MONTH]: 'Monthly irradiation',
      },
      [ESolarExposureType.SHADING_FACTOR]: {
        [ESolarExposureInterval.YEAR]: 'Yearly shading factor',
        [ESolarExposureInterval.MONTH]: 'Monthly shading factor',
      },
    },
    solarTooltipUnit: {
      [ESolarExposureType.IRRADIATION]: 'kWh/m²',
      [ESolarExposureType.SHADING_FACTOR]: '%',
    },
    usageTooltipTitle: {
      livingSpaceRatio: 'Living space',
      businessSpaceRatio: 'Business space',
      sideUsageSpaceRatio: 'Side usage space',
    },
  },
  landscape: {
    addVegetationType: EVegetationType.MAPLE,
  },
  terrainGeometry: {
    id: undefined,
    bottom: undefined,
    overlay: undefined,
    terrainData: undefined,
  },
};

const store = proxy<IStoreState>(initialStoreState);

export const derivedStore = derive({
  selectedObjects: (get) => {
    const { selectedObjects } = get(store.selections);
    const selectedObjectsArray = Object.entries(selectedObjects);

    const groupedSelectedObjects = selectedObjectsArray.reduce(
      (acc, [id, type]) => {
        if (!acc[type]) {
          acc[type] = [];
        }

        acc[type].push(id);

        return acc;
      },
      {} as Record<EObjectType, string[]>,
    );

    return {
      selectedBuildingIds: groupedSelectedObjects[EObjectType.BUILDING] ?? [],
      selectedFloorIds: groupedSelectedObjects[EObjectType.FLOOR] ?? [],
      selectedSegmentIds: groupedSelectedObjects[EObjectType.SEGMENT] ?? [],
      selectedParkingIds: groupedSelectedObjects[EObjectType.PARKING_LOT] ?? [],
      selectedVegetationIds: groupedSelectedObjects[EObjectType.VEGETATION] ?? [],
      selectedBufferIds: groupedSelectedObjects[EObjectType.BUFFER] ?? [],
      selectedTransformCornerIds: groupedSelectedObjects[EObjectType.TRANSFORM_CORNER] ?? [],
      selectedTransformWallIds: groupedSelectedObjects[EObjectType.TRANSFORM_WALL] ?? [],
    };
  },
});

export default store;
