import { Theme } from '@mui/material';

const styles = {
  modal: {
    maxHeight: '100dvh',
    zIndex: (theme: Theme) => ({
      xs: theme.zIndex.drawer + 1,
      lg: theme.constants.ProjectModal.zIndex,
    }),
    pt: (theme: Theme) => ({
      lg: `${theme.constants.ProjectTopBar.height}px`,
    }),
    px: 4,
    pb: 2,
    transition: (theme: Theme) => theme.transitions.create('padding'),
  },
  modalSidebarOpen: {
    pl: (theme: Theme) => ({
      lg: `calc(${theme.constants.Sidebar.width}px + ${theme.spacing(6)})`,
    }),
  },
  container: {
    borderRadius: 2,
    maxHeight: '100%',
    overflow: 'auto',
    opacity: 0.5,
  },
  modalContent: {
    width: '100vw',
    maxWidth: {
      xs: 'min(760px, 100%)',
      sm: 'min(760px, 100%)',
    },
    my: {
      sm: 0,
      lg: 0,
    },
  },
  modalContentWrapper: {
    p: 0,
  },
  content: {
    p: 6,
  },
  modalPaddingX: { xs: 4, lg: 6, xl: 8 },
  modalPaddingY: { xs: 4, lg: 5, xl: 6 },
};

export default styles;
