import { createSelector } from 'reselect';
import { ExploreMapState } from '../../pages/App/Explore/ExploreMap/state/map';
import { RootState } from '../store';

export const mapSelector: (state: RootState) => ExploreMapState = (state: RootState) => state.exploreMap;
export const mapRulerSelector = createSelector(mapSelector, (state: ExploreMapState) => state.ruler);

export const mapDrawSelector = createSelector(mapSelector, (state: ExploreMapState) => state.draw);

export const mapMergeSelector = createSelector(mapSelector, (state: ExploreMapState) => state.merge);

export const mapEditEnabledSelector = createSelector(
  mapDrawSelector,
  mapMergeSelector,
  (draw: ExploreMapState['draw'], merge: ExploreMapState['merge']) => draw.isOpen || merge.isOpen,
);

export const mapActivePlotEgridSelector = createSelector(
  mapSelector,
  (state: ExploreMapState) => state.merge.activePlotEgrid,
);

export const mapMutatedPlotSelector = createSelector(mapSelector, (state: ExploreMapState) => state.draw.mutatedPlot);

export const mapSavedMutatedPlotSelector = createSelector(
  mapSelector,
  (state: ExploreMapState) => state.draw.savedPlot,
);
