import DataOverviewModalContent from 'components/modals/DataOverviewModal/DataOverviewModalContent';
import Modal from 'components/modals/Modal';
import useModals from 'hooks/useModals';
import React from 'react';
import { useDispatch } from 'react-redux';
import { EModal } from 'types/enums/Modal';

const DataOverviewModal = () => {
  const dispatch = useDispatch();

  const { modals, closeModal } = useModals();

  const handleCloseModal = () => {
    dispatch(closeModal(EModal.DATA_OVERVIEW));
  };

  return (
    <Modal
      open={modals.dataOverview.open}
      handleClose={handleCloseModal}
      ContentSx={{
        maxWidth: {
          xs: 'none',
        },
      }}
      ContentWrapperSx={{ p: 0 }}
    >
      <DataOverviewModalContent />
    </Modal>
  );
};

export default DataOverviewModal;
