import { ProjectIdRequest } from 'api/dtos/general/request';
import {
  ChangeProjectSettingsRequest,
  CreateProjectRequest,
  GetProjectUtilizationByDtoRequest,
  SetConfidenceLevelRequest,
  SetLawZoneRequest,
  SetProjectArea,
} from 'api/dtos/project/input';
import {
  CreateProjectResponse,
  GetProjectResponse,
  ProjectSiaAreasResponse,
  RentIncomeResponse,
  SaleIncomeResponse,
} from 'api/dtos/project/output';
import { backendApi } from 'state/services/backend/api';
import { EBackendTag } from 'state/services/backend/types';
import { BKPCostsResponseDTO, EBKPCostsResponseDTO } from 'types/building/queries';
import { IProjectUtilization } from 'types/project/utilization';
import { RequestMethod } from 'types/request';

export const projectApiSlice = backendApi.injectEndpoints({
  endpoints: (builder) => ({
    createProject: builder.mutation<CreateProjectResponse, CreateProjectRequest>({
      query: (body) => ({
        url: `/api/project`,
        method: RequestMethod.POST,
        body,
      }),
      invalidatesTags: [EBackendTag.PROJECT, EBackendTag.CREDITS],
    }),
    getProject: builder.query<GetProjectResponse, ProjectIdRequest>({
      query: ({ projectId }) => ({
        url: `/api/project/${projectId}`,
        method: RequestMethod.GET,
      }),
      providesTags: [EBackendTag.PROJECT],
    }),
    changeProjectSettings: builder.mutation<any, ChangeProjectSettingsRequest>({
      query: ({ projectId, name, description }) => ({
        url: `/api/project/${projectId}/change-settings`,
        method: RequestMethod.PUT,
        body: { name, description },
      }),
      invalidatesTags: [EBackendTag.PROJECT],
    }),
    getProjectBkpCost: builder.query<BKPCostsResponseDTO, ProjectIdRequest>({
      query: ({ projectId }) => ({
        url: `/api/project/${projectId}/costs/bkp`,
        method: RequestMethod.GET,
      }),
      providesTags: [EBackendTag.PROJECT_COST],
    }),
    getProjectEbkpCost: builder.query<EBKPCostsResponseDTO, ProjectIdRequest>({
      query: ({ projectId }) => ({
        url: `/api/project/${projectId}/costs/ebkp`,
        method: RequestMethod.GET,
      }),
      providesTags: [EBackendTag.PROJECT_COST],
    }),
    getProjectRentIncome: builder.query<RentIncomeResponse, ProjectIdRequest>({
      query: ({ projectId }) => ({
        url: `/api/project/${projectId}/incomes/rent`,
        method: RequestMethod.GET,
      }),
      providesTags: [EBackendTag.PROJECT_INCOME],
    }),
    getProjectSaleIncome: builder.query<SaleIncomeResponse, ProjectIdRequest>({
      query: ({ projectId }) => ({
        url: `/api/project/${projectId}/incomes/sale`,
        method: RequestMethod.GET,
      }),
      providesTags: [EBackendTag.PROJECT_INCOME],
    }),
    getProjectSiaAreas: builder.query<ProjectSiaAreasResponse, ProjectIdRequest>({
      query: ({ projectId }) => ({
        url: `/api/project-areas/${projectId}/sia416`,
        method: RequestMethod.GET,
      }),
      providesTags: [EBackendTag.PROJECT_AREAS],
    }),
    setProjectArea: builder.mutation<any, SetProjectArea>({
      query: ({ projectId, area }) => ({
        url: `/api/project-areas/${projectId}`,
        method: RequestMethod.POST,
        body: {
          area,
        },
      }),
      invalidatesTags: [
        EBackendTag.PROJECT,
        EBackendTag.PROJECT_AREAS,
        EBackendTag.UTILIZATION,
        EBackendTag.PROJECT_RULES_CHECK,
      ],
    }),
    getProjectUtilization: builder.query<IProjectUtilization, ProjectIdRequest>({
      query: ({ projectId }) => ({
        url: `/api/project-utilization/${projectId}`,
        method: RequestMethod.GET,
      }),
      providesTags: [EBackendTag.UTILIZATION],
    }),
    getProjectUtilizationByDTO: builder.query<IProjectUtilization, GetProjectUtilizationByDtoRequest>({
      query: ({ projectId, buildings }) => ({
        url: `/api/project-utilization/${projectId}/by-dto`,
        method: RequestMethod.POST,
        body: { buildings },
      }),
    }),
    setConfidenceLevel: builder.mutation<void, SetConfidenceLevelRequest>({
      query: ({ projectId, confidenceLevel }) => ({
        url: `/api/project/${projectId}/confidenceLevel/${confidenceLevel}`,
        method: RequestMethod.PUT,
      }),
      invalidatesTags: [EBackendTag.PROJECT, EBackendTag.ENERGY],
    }),
    setLawZone: builder.mutation<void, SetLawZoneRequest>({
      query: ({ projectId, zoneId }) => ({
        url: `/api/project/${projectId}/lawZone/${zoneId}`,
        method: RequestMethod.PUT,
      }),
      invalidatesTags: [
        EBackendTag.PROJECT,
        EBackendTag.PROJECT_RULES_CHECK,
        EBackendTag.USER_PROJECT_RULES,
        EBackendTag.DATA_OVERVIEW,
      ],
    }),
  }),
});

export const {
  useCreateProjectMutation,
  useGetProjectQuery,
  useChangeProjectSettingsMutation,
  useGetProjectBkpCostQuery,
  useGetProjectEbkpCostQuery,
  useGetProjectRentIncomeQuery,
  useGetProjectSaleIncomeQuery,
  useGetProjectSiaAreasQuery,
  useGetProjectUtilizationQuery,
  useGetProjectUtilizationByDTOQuery,
  useSetConfidenceLevelMutation,
  useSetLawZoneMutation,
} = projectApiSlice;

export const useGetProjectState = projectApiSlice.endpoints.getProject.useQueryState;

export const useGetProjectSiaAreasState = projectApiSlice.endpoints.getProjectSiaAreas.useQueryState;

export const useGetProjectUtilizationState = projectApiSlice.endpoints.getProjectUtilization.useQueryState;

export const useGetProjectBkpCostState = projectApiSlice.endpoints.getProjectBkpCost.useQueryState;

export const useGetProjectEbkpCostState = projectApiSlice.endpoints.getProjectEbkpCost.useQueryState;

export const useGetProjectRentIncomeState = projectApiSlice.endpoints.getProjectRentIncome.useQueryState;

export const useGetProjectSaleIncomeState = projectApiSlice.endpoints.getProjectSaleIncome.useQueryState;
