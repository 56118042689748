import { GetDataOverviewRequest } from 'api/dtos/general/data/dto/request';
import { GetDataOverviewResponse } from 'api/dtos/general/data/dto/response';
import { backendApi } from 'state/services/backend/api';
import { RequestMethod } from 'types/request';
import { getQueryParameters } from 'utils/url';
import { EBackendTag } from '../../types';

export const dataOverviewApiSlice = backendApi.injectEndpoints({
  endpoints: (builder) => ({
    getDataOverview: builder.query<GetDataOverviewResponse, GetDataOverviewRequest>({
      query: (query: GetDataOverviewRequest) => ({
        url: `/api/data-overview${getQueryParameters(query)}`,
        method: RequestMethod.GET,
      }),
      providesTags: [EBackendTag.DATA_OVERVIEW],
    }),
  }),
});

export const { useGetDataOverviewQuery } = dataOverviewApiSlice;
