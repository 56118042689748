export enum EModal {
  CALENDLY = 'calendly',
  CONTACT = 'contact',
  DEMO = 'demo',
  FAHRLANDER_SETTINGS = 'fahrlanderSettings',
  HELP = 'help',
  PROJECT_AREAS = 'projectAreas',
  PROJECT_CHECKLIST = 'projectChecklist',
  PROJECT_COSTS = 'projectCosts',
  PROJECT_DETAILS = 'projectDetails',
  PROJECT_ENERGY = 'projectEnergy',
  PROJECT_GEOMETRY = 'projectGeometry',
  PROJECT_INCOME = 'projectIncome',
  PROJECT_SHARE = 'projectShare',
  PROJECT_USAGE = 'projectUsage',
  PROJECT_UTILIZATION = 'projectUtilization',
  SHOP = 'shop',
  SUBSCRIPTION = 'subscription',
  DATA_OVERVIEW = 'dataOverview',
}
