export type TweenConfig = {
  from: number;
  to: number;
  duration: number;
  easing?: string | ((value: number) => number);
  delay?: number;
  loop?: boolean;
};

type Tween = {
  config: TweenConfig;
  progress: number;
};

export type MoveConfig = {
  inertia: number;
  target: number;
};

type MoveAnimation = {
  config: MoveConfig;
  active: boolean;
};

export type AnimationConfig = {
  tweenConfig?: TweenConfig;
  moveConfig?: MoveConfig;
  effect?: (timeDelta: number, prevValue: number) => number;
};

enum EAnimationType {
  TWEEN = 'tween',
  MOVE = 'move',
  EFFECT = 'effect',
  NONE = 'none',
}

class AnimatedValue {
  private static readonly MOVE_FINISH_THRESHOLD = 0.0001;
  private value: number;
  private changeListeners: ((value: number, progress: number) => void)[] = [];
  private completeListeners: (() => void)[] = [];
  private loopCompleteListeners: (() => void)[] = [];
  private moveCompleteListeners: (() => void)[] = [];
  private tween: Tween | null = null;
  private moveAnimation: MoveAnimation | null = null;
  private lastTimestamp: number | null = null;
  private effect: ((timeDelta: number, prevValue: number) => number) | null = null;
  private activeAnimation: EAnimationType = EAnimationType.NONE;

  constructor(initialValue: number, animationConfig: AnimationConfig = {}) {
    const { tweenConfig, moveConfig, effect } = animationConfig;

    this.value = initialValue;
    if (tweenConfig) this.setTween(tweenConfig);

    const defaultMoveConfig = { target: initialValue, inertia: 10 };
    this.setMove({ ...defaultMoveConfig, ...moveConfig });

    if (effect) this.setEffect(effect);
  }

  public setTween(config: TweenConfig): void {
    this.tween = {
      config,
      progress: 0,
    };
  }

  public setMove(config: MoveConfig, passive = false): void {
    this.moveAnimation = {
      config,
      active: !passive,
    } as MoveAnimation;
  }

  public setEffect(effect: (timeDelta: number, prevValue: number) => number): void {
    this.effect = effect;
  }

  public isAnimating(): boolean {
    return this.activeAnimation !== EAnimationType.NONE;
  }

  private hasReachedMoveTarget(): boolean {
    if (this.moveAnimation) {
      const { config, active } = this.moveAnimation;
      const { target } = config;
      const { value } = this;
      const diff = Math.abs(value - target);
      return active && Math.abs(diff / target) < AnimatedValue.MOVE_FINISH_THRESHOLD;
    }
    return false;
  }

  private setActiveAnimation(type: EAnimationType): void {
    switch (this.activeAnimation) {
      case EAnimationType.TWEEN:
        this.lastTimestamp = null;
        break;
      case EAnimationType.MOVE:
        if (this.moveAnimation) this.moveAnimation.config.target = this.value;
        break;
    }

    this.activeAnimation = type;
  }

  public setTweenProgress(progress: number): void {
    if (this.tween) {
      this.tween.progress = Math.max(0, Math.min(progress, 1));
      // apply progress to value
      this.applyProgressToValue();
      if (progress === 1 && this.activeAnimation === EAnimationType.TWEEN) {
        if (this.tween.config.loop) {
          this.notifyLoopCompleteListeners();
          this.tween.progress = 0;
          this.lastTimestamp = null;
        } else {
          this.pauseTween();
          this.notifyCompleteListeners();
        }
      }
    }
  }

  private applyProgressToValue(): void {
    if (this.tween) {
      const { config, progress } = this.tween;
      const { from, to, easing = 'linear' } = config;

      const ease = typeof easing === 'string' ? easings[easing] : easing;
      const easedProgress = ease(progress);
      this.value = from + (to - from) * easedProgress;
      this.notifyChangeListeners();
    }
  }

  public getValue(): number {
    return this.value;
  }

  public calculateProgress(): number {
    if (this.tween) {
      const { to, from } = this.tween.config;
      return to >= from
        ? (Math.max(from, Math.min(this.value, to)) - from) / (to - from)
        : (Math.max(to, Math.min(this.value, from)) - from) / (to - from);
    } else {
      return 0;
    }
  }

  public setValue(value: number): void {
    this.value = value;
    this.notifyChangeListeners();
  }

  private animate(type: EAnimationType): void {
    if (this.activeAnimation === type || type === EAnimationType.NONE) return;

    switch (type) {
      case EAnimationType.TWEEN:
        if (this.tween) {
          this.setActiveAnimation(type);
          window.requestAnimationFrame((t) => this.stepTween(t));
        }
        break;
      case EAnimationType.MOVE:
        if (this.moveAnimation) {
          this.setActiveAnimation(type);
          window.requestAnimationFrame((t) => this.stepMove(t));
        }
        break;
      case EAnimationType.EFFECT:
        if (this.effect) {
          this.setActiveAnimation(type);
          window.requestAnimationFrame((t) => this.stepEffect(t));
        }
        break;
    }
  }

  private stepTween(timestamp: number) {
    if (!this.tween) {
      this.setActiveAnimation(EAnimationType.NONE);
      return;
    }

    if (this.activeAnimation !== EAnimationType.TWEEN) return;

    if (this.lastTimestamp === null) this.lastTimestamp = timestamp;
    const timeDelta = timestamp - this.lastTimestamp;
    this.lastTimestamp = timestamp;

    const { config, progress } = this.tween;
    const { duration } = config;
    const progressDelta = timeDelta / duration;
    const newProgress = Math.min(1, progress + progressDelta);
    this.setTweenProgress(newProgress);

    if (!(newProgress === 1 && !this.tween.config.loop)) window.requestAnimationFrame((t) => this.stepTween(t));
  }

  private stepMove(timestamp: number) {
    if (!this.moveAnimation || this.hasReachedMoveTarget()) {
      this.setActiveAnimation(EAnimationType.NONE);
      return;
    }

    if (this.activeAnimation !== EAnimationType.MOVE) return;

    if (this.lastTimestamp === null) this.lastTimestamp = timestamp;
    const timeDelta = timestamp - this.lastTimestamp;
    this.lastTimestamp = timestamp;

    const { config } = this.moveAnimation;
    const { target, inertia } = config;
    const newValue = move(this.value, target, timeDelta, inertia);
    this.setValue(newValue);
    this.notifyChangeListeners();
    if (this.hasReachedMoveTarget()) {
      this.lastTimestamp = null;
      this.setActiveAnimation(EAnimationType.NONE);
      this.notifyMoveCompleteListeners();
      return;
    }

    window.requestAnimationFrame((t) => this.stepMove(t));
  }

  private stepEffect(timestamp: number) {
    if (!this.effect) {
      this.setActiveAnimation(EAnimationType.NONE);
      return;
    }
    if (this.activeAnimation !== EAnimationType.EFFECT) return;

    if (this.lastTimestamp === null) this.lastTimestamp = timestamp;
    const timeDelta = timestamp - this.lastTimestamp;
    this.lastTimestamp = timestamp;

    this.setValue(this.effect(timeDelta, this.value));
    window.requestAnimationFrame((t) => this.stepEffect(t));
  }

  // CONTROLS
  public playTween(): void {
    if (!this.tween) return;

    // Update progress based on current value to resume playback from current position
    const { from, to, easing = 'linear' } = this.tween.config;
    let low = 0;
    let high = 1;
    let mid = 0;
    let midValue = 0;
    let unEasedProgress;
    const maxIterations = 100;
    const ease = typeof easing === 'string' ? easings[easing] : easing;

    for (let i = 0; i < maxIterations; i++) {
      mid = (low + high) / 2;
      midValue = from + (to - from) * ease(mid);
      if (Math.abs(midValue - this.value) < Number.EPSILON) {
        unEasedProgress = mid;
        break;
      } else if (midValue < this.value) {
        low = mid;
      } else {
        high = mid;
      }
    }
    unEasedProgress = (low + high) / 2;
    this.setTweenProgress(unEasedProgress);

    this.animate(EAnimationType.TWEEN);
  }

  public pauseTween(): void {
    if (this.activeAnimation === EAnimationType.TWEEN && this.tween) {
      this.lastTimestamp = null;
      this.setActiveAnimation(EAnimationType.NONE);
    }
  }

  public stop(): void {
    switch (this.activeAnimation) {
      case EAnimationType.TWEEN:
        if (this.tween) {
          this.pauseTween();
          this.setTweenProgress(0);
        }
        break;
      case EAnimationType.MOVE:
        if (this.moveAnimation) {
          this.moveAnimation.config.target = this.value;
        }
        break;
    }

    this.lastTimestamp = null;
    this.setActiveAnimation(EAnimationType.NONE);
  }

  public playEffect(): void {
    this.animate(EAnimationType.EFFECT);
  }

  public moveTo(target: number, inertia = this.moveAnimation?.config.inertia ?? 10): void {
    this.setMove({ target, inertia });
    this.animate(EAnimationType.MOVE);
  }

  // EVENTS & LISTENERS
  public onChange(listener: (value: number, progress: number) => void): { removeListener: () => void } {
    this.changeListeners.push(listener);

    return {
      removeListener: () => {
        this.removeChangeListener(listener);
      },
    };
  }

  public onComplete(listener: () => void): { removeListener: () => void } {
    this.completeListeners.push(listener);

    return {
      removeListener: () => {
        this.removeCompleteListener(listener);
      },
    };
  }

  public onLoopComplete(listener: () => void): { removeListener: () => void } {
    this.loopCompleteListeners.push(listener);

    return {
      removeListener: () => {
        this.removeLoopCompleteListener(listener);
      },
    };
  }

  public onMoveComplete(listener: () => void): { removeListener: () => void } {
    this.moveCompleteListeners.push(listener);

    return {
      removeListener: () => {
        this.removeMoveCompleteListener(listener);
      },
    };
  }

  private removeMoveCompleteListener(listener: () => void): void {
    const index = this.moveCompleteListeners.indexOf(listener);
    if (index !== -1) {
      this.moveCompleteListeners.splice(index, 1);
    }
  }

  private removeLoopCompleteListener(listener: () => void): void {
    const index = this.loopCompleteListeners.indexOf(listener);
    if (index !== -1) {
      this.loopCompleteListeners.splice(index, 1);
    }
  }

  private removeCompleteListener(listener: () => void): void {
    const index = this.completeListeners.indexOf(listener);
    if (index !== -1) {
      this.completeListeners.splice(index, 1);
    }
  }

  private removeChangeListener(listener: (value: number, progress: number) => void): void {
    const index = this.changeListeners.indexOf(listener);
    if (index !== -1) {
      this.changeListeners.splice(index, 1);
    }
  }

  private notifyChangeListeners(): void {
    for (const listener of this.changeListeners) {
      listener(this.value, this.tween?.progress || 0);
    }
  }

  private notifyCompleteListeners(): void {
    for (const listener of this.completeListeners) {
      listener();
    }
  }

  private notifyLoopCompleteListeners(): void {
    for (const listener of this.loopCompleteListeners) {
      listener();
    }
  }

  private notifyMoveCompleteListeners(): void {
    for (const listener of this.moveCompleteListeners) {
      listener();
    }
  }
}

export default AnimatedValue;

function move(source: number, target: number, timeDelta: number, inertia: number) {
  if (timeDelta > 1000) timeDelta = 1000;
  if (inertia === null || inertia === undefined) {
    inertia = 20;
  }
  let dir = 1;
  if (source > target) dir = -1;
  const diff = ((-dir * timeDelta) / (inertia * 16.66)) * Math.abs(source - target);
  source = source - diff;
  if (dir === 1 && source > target) source = target;
  if (dir === -1 && source < target) source = target;
  return source;
}

const easings: Record<string, (t: number) => number> = {
  linear: (t: number) => t,
  inQuad: (t: number) => t * t,
  outQuad: (t: number) => t * (2 - t),
  inOutQuad: (t: number) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t),
  inCubic: (t: number) => t * t * t,
  outCubic: (t: number) => --t * t * t + 1,
  inOutCubic: (t: number) => (t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1),
  inQuart: (t: number) => t * t * t * t,
  outQuart: (t: number) => 1 - --t * t * t * t,
  inOutQuart: (t: number) => (t < 0.5 ? 8 * t * t * t * t : 1 - 8 * --t * t * t * t),
  inQuint: (t: number) => t * t * t * t * t,
  outQuint: (t: number) => 1 + --t * t * t * t * t,
  inOutQuint: (t: number) => (t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t),
  inSine: (t: number) => -Math.cos(t * (Math.PI / 2)) + 1,
  outSine: (t: number) => Math.sin(t * (Math.PI / 2)),
  inOutSine: (t: number) => -0.5 * (Math.cos(Math.PI * t) - 1),
  inExpo: (t: number) => Math.pow(2, 10 * (t - 1)),
  outExpo: (t: number) => -Math.pow(2, -10 * t) + 1,
  inOutExpo: (t: number) => {
    t /= 0.5;
    if (t < 1) return 0.5 * Math.pow(2, 10 * (t - 1));
    t--;
    return 0.5 * (-Math.pow(2, -10 * t) + 2);
  },
  inCirc: (t: number) => -1 * (Math.sqrt(1 - t * t) - 1),
  outCirc: (t: number) => Math.sqrt(1 - (t - 1) * (t - 1)),
  inOutCirc: (t: number) => {
    t /= 0.5;
    if (t < 1) return -0.5 * (Math.sqrt(1 - t * t) - 1);
    t -= 2;
    return 0.5 * (Math.sqrt(1 - t * t) + 1);
  },
  inBack: (t: number) => {
    const s = 1.70158;
    return t * t * ((s + 1) * t - s);
  },
  outBack: (t: number) => {
    const s = 1.70158;
    return --t * t * ((s + 1) * t + s) + 1;
  },
  inOutBack: (t: number) => {
    let s = 1.70158;
    if ((t /= 0.5) < 1) return 0.5 * (t * t * (((s *= 1.525) + 1) * t - s));
    return 0.5 * ((t -= 2) * t * (((s *= 1.525) + 1) * t + s) + 2);
  },
  inBounce: (t: number) => 1 - easings.outBounce(1 - t),
  outBounce: (t: number) => {
    if (t < 1 / 2.75) {
      return 7.5625 * t * t;
    } else if (t < 2 / 2.75) {
      return 7.5625 * (t -= 1.5 / 2.75) * t + 0.75;
    } else if (t < 2.5 / 2.75) {
      return 7.5625 * (t -= 2.25 / 2.75) * t + 0.9375;
    } else {
      return 7.5625 * (t -= 2.625 / 2.75) * t + 0.984375;
    }
  },
  inOutBounce: (t: number) => {
    if (t < 0.5) return easings.inBounce(t * 2) * 0.5;
    return easings.outBounce(t * 2 - 1) * 0.5 + 0.5;
  },
  inElastic: (t: number) => {
    let s = 1.70158;
    let p = 0;
    let a = 1;
    if (t === 0) return 0;
    if (t === 1) return 1;
    if (!p) p = 0.3;
    if (a < 1) {
      a = 1;
      s = p / 4;
    } else s = (p / (2 * Math.PI)) * Math.asin(1 / a);
    return -(a * Math.pow(2, 10 * (t -= 1)) * Math.sin(((t - s) * (2 * Math.PI)) / p));
  },
  outElastic: (t: number) => {
    let s = 1.70158;
    let p = 0;
    let a = 1;
    if (t === 0) return 0;
    if (t === 1) return 1;
    if (!p) p = 0.3;
    if (a < 1) {
      a = 1;
      s = p / 4;
    } else s = (p / (2 * Math.PI)) * Math.asin(1 / a);
    return a * Math.pow(2, -10 * t) * Math.sin(((t - s) * (2 * Math.PI)) / p) + 1;
  },
  inOutElastic: (t: number) => {
    let s = 1.70158;
    let p = 0;
    let a = 1;
    if (t === 0) return 0;
    if ((t /= 0.5) === 2) return 1;
    if (!p) p = 0.45;
    if (a < 1) {
      a = 1;
      s = p / 4;
    } else s = (p / (2 * Math.PI)) * Math.asin(1 / a);
    if (t < 1) return -0.5 * (a * Math.pow(2, 10 * (t -= 1)) * Math.sin(((t - s) * (2 * Math.PI)) / p));
    return a * Math.pow(2, -10 * (t -= 1)) * Math.sin(((t - s) * (2 * Math.PI)) / p) * 0.5 + 1;
  },
};
