import * as turf from '@turf/turf';
import { union } from '@turf/union';
import { Feature, Polygon } from 'geojson';
import { MapGeoJSONFeature, QuerySourceFeatureOptions } from 'maplibre-gl';
import { ICadastre } from 'pages/App/Explore/ExploreMap/state/map';
import { CADASTRE_SOURCE_ID, CONSTRUCTION_LINES_SOURCE_ID, LAND_COVER_SOURCE_ID } from '../constants';
import groupBy from 'lodash/groupBy';

interface Map {
  querySourceFeatures: (sourceId: string, options?: QuerySourceFeatureOptions) => MapGeoJSONFeature[];
}

export const querySourceCadastres = (map: Map, options?: QuerySourceFeatureOptions): Feature<Polygon, ICadastre>[] => {
  return map.querySourceFeatures(CADASTRE_SOURCE_ID, {
    sourceLayer: 'default',
    ...options,
  }) as unknown as Feature<Polygon, ICadastre>[];
};

export const querySourceBuildings = (map: Map, options?: QuerySourceFeatureOptions) => {
  return map.querySourceFeatures(LAND_COVER_SOURCE_ID, {
    sourceLayer: LAND_COVER_SOURCE_ID,
    ...options,
  });
};

export const calculateUnionedGeometry = (features: Feature<Polygon>[]): Polygon => {
  if (features.length === 1) {
    return features[0].geometry!;
  } else if (features.length === 0) {
    return turf.polygon([]).geometry;
  }
  return union(turf.featureCollection<Polygon>(features))?.geometry as Polygon;
};

export const getUnionedFeaturesByAttribute = (
  features: Feature<Polygon>[],
  property: string,
): Feature<Polygon, ICadastre>[] => {
  return Object.values(groupBy(features, (f) => f.properties?.[property]))
    .map((features) => {
      if (!features || features.length === 0) return;
      const { id, properties } = features[0];
      const geometry = calculateUnionedGeometry(features);
      return turf.feature(geometry, properties, { id });
    })
    .filter(Boolean) as Feature<Polygon, ICadastre>[];
};

export const getUnionedFeaturesById = (features: Feature<Polygon>[]): Feature<Polygon>[] => {
  return Object.values(groupBy(features, (f) => f.id!))
    .map((features) => {
      if (!features || features.length === 0) return;
      const { id, properties } = features[0];
      const geometry = calculateUnionedGeometry(features);
      return turf.feature(geometry, properties, { id });
    })
    .filter(Boolean) as Feature<Polygon>[];
};

export const querySourceConstructionLines = (map: Map, options?: QuerySourceFeatureOptions) => {
  return map.querySourceFeatures(CONSTRUCTION_LINES_SOURCE_ID, {
    sourceLayer: CONSTRUCTION_LINES_SOURCE_ID,
    ...options,
  });
};

// export const querySourceLawZones = (map: Map, options?: QuerySourceFeatureOptions): Feature<Polygon, ICadastre>[] => {
//   return map.querySourceFeatures('law-source', {
//     sourceLayer: 'default',
//     ...options,
//   });
// };
