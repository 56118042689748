import { TContinuousPathChain, TOnlyOnePathPart } from '../types';
import {
  EApplicationMode,
  EApplicationStep,
  EApplicationTool,
  EApplicationTopic,
  ESelectionFilter,
  IApplicationPath,
} from '../store/store';
import store from '../store';

const getValidPathWithOnlyOnePathPart = (path: TOnlyOnePathPart): IApplicationPath => {
  const { applicationPath } = store.general;
  const { step, topic, mode, tool, selectionFilter } = path;

  // In an ordered set of step, topic, mode and tool, check if there is a new value for item, if not use current value. If a new value is found, all the next items should be set to default value.
  const newStep = step || applicationPath.step;
  const newTopic = step ? getDefaultTopic(newStep) : topic || applicationPath.topic;
  const newMode = step || topic ? EApplicationMode.VIEW : mode || applicationPath.mode;
  const newTool = step || topic || mode ? getDefaultTool(newStep, newTopic) : tool || applicationPath.tool;
  const newSelectionFilter =
    step || topic || mode || tool ? ESelectionFilter.NONE : selectionFilter || applicationPath.selectionFilter;

  return {
    step: newStep,
    topic: newTopic,
    mode: newMode,
    tool: newTool,
    selectionFilter: newSelectionFilter,
  };
};

const getValidPathWithContinuousPathChain = (path: TContinuousPathChain): IApplicationPath => {
  const { step, topic, mode, tool, selectionFilter } = path;

  // In an ordered set of step, topic, mode and tool, check if there is a new value for item, if not use current value. If a new value is found, all the next items should be set to default value.
  const newStep = step;
  const newTopic = topic;
  const newMode = mode || EApplicationMode.VIEW;
  const newTool = tool || getDefaultTool(newStep, newTopic);
  const newSelectionFilter = selectionFilter || ESelectionFilter.NONE;

  return {
    step: newStep,
    topic: newTopic,
    mode: newMode,
    tool: newTool,
    selectionFilter: newSelectionFilter,
  };
};

export const getValidPath = (path: TOnlyOnePathPart | TContinuousPathChain): IApplicationPath => {
  const isContinuousPathChain = Object.values(path).filter((item) => item !== undefined).length > 1;

  if (isContinuousPathChain) {
    return getValidPathWithContinuousPathChain(path as TContinuousPathChain);
  } else {
    return getValidPathWithOnlyOnePathPart(path as TOnlyOnePathPart);
  }
};

export const getDefaultTopic = (step: EApplicationStep): EApplicationTopic => {
  switch (step) {
    case EApplicationStep.DESIGN:
      return EApplicationTopic.FORM;
    default:
      return EApplicationTopic.DEFAULT;
  }
};

export const getDefaultTool = (step: EApplicationStep, topic: EApplicationTopic): EApplicationTool => {
  switch (step) {
    case EApplicationStep.DESIGN:
      switch (topic) {
        case EApplicationTopic.FORM:
          return EApplicationTool.SELECT;
        case EApplicationTopic.FACADE:
          return EApplicationTool.BUILDING;
        case EApplicationTopic.SERVICES:
          return EApplicationTool.BUILDING;
        case EApplicationTopic.LANDSCAPE:
          return EApplicationTool.VEGETATION_SELECT;
        case EApplicationTopic.PARKING_LOTS:
          return EApplicationTool.SELECT;
        default:
          return EApplicationTool.DEFAULT;
      }
    default:
      return EApplicationTool.DEFAULT;
  }
};
