import { proxy } from 'valtio';
import { derive } from 'valtio/utils';
import { ELoadingState } from '../types';

export interface ILoadingState {
  loadingState: ELoadingState;
}

export interface IExternalLoadingState {
  vegetations: ILoadingState;
  terrain: ILoadingState;
  existingBuildings: ILoadingState;
}

export interface IInternalLoadingState {
  vegetations: ILoadingState;
  terrain: ILoadingState;
  existingBuildings: ILoadingState;
}

interface IExistingBuildingsLoaderStoreState {
  existingBuildings: Record<string, ELoadingState>;
}

export const existingBuildingsLoaderStore = proxy<IExistingBuildingsLoaderStoreState>({
  existingBuildings: {},
});

interface ILoaderStoreState {
  external: IExternalLoadingState;
  internal: IInternalLoadingState;
}

const externalLoaderStore = proxy<IExternalLoadingState>({
  vegetations: {
    loadingState: ELoadingState.LOADING,
  },
  terrain: {
    loadingState: ELoadingState.LOADING,
  },
  existingBuildings: {
    loadingState: ELoadingState.LOADING,
  },
});

const internalLoaderStore = proxy<IInternalLoadingState>({
  vegetations: {
    loadingState: ELoadingState.LOADING,
  },
  terrain: {
    loadingState: ELoadingState.LOADING,
  },
  existingBuildings: derive({
    loadingState: (get) => {
      const existingBuildings = get(existingBuildingsLoaderStore).existingBuildings;
      const buildingLoadingStates = Object.values(existingBuildings);

      const isSuccessOrError = buildingLoadingStates.length
        ? buildingLoadingStates.every((loadingState) =>
            [ELoadingState.SUCCESS, ELoadingState.ERROR].includes(loadingState),
          )
        : get(externalLoaderStore).existingBuildings.loadingState !== ELoadingState.LOADING;

      return isSuccessOrError ? ELoadingState.SUCCESS : ELoadingState.LOADING;
    },
  }),
});

const loaderStore = proxy<ILoaderStoreState>({
  external: externalLoaderStore,
  internal: internalLoaderStore,
});

export default loaderStore;
