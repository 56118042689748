import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { EMapLineType } from 'components/features/Map/types';
import { Feature, FeatureCollection, LineString, Polygon } from 'geojson';
import cloneDeep from 'lodash/cloneDeep';

export enum EMapType {
  ROADMAP = 'roadmap',
  SATELLITE = 'satellite',
  CADASTRE = 'cadastre',
}

export enum EMapOverlay {
  CONTAMINATION = 'contamination',
  HAZARDS = 'hazards',
  HERITAGE = 'heritage',
  ORTSBILD = 'ortsbild',
  TRAIN_NOISE_DAYTIME = 'trainNoiseDaytime',
  TRAIN_NOISE_NIGHTTIME = 'trainNoiseNighttime',
  CAR_NOISE_DAYTIME = 'carNoiseDaytime',
  CAR_NOISE_NIGHTTIME = 'carNoiseNighttime',
}

type MapVisibilityState = Record<EMapOverlay, boolean>;

export interface MapState {
  selectedFeatureIndexes: number[];
  controls: {
    visibility: MapVisibilityState;
    type: EMapType;
    terrain: boolean;
  };
  toolbox: {
    mode: 'view' | 'edit-modify' | 'edit-transform' | 'draw-polygon' | 'draw-90-polygon' | 'draw-linestring' | 'fill';
    plugins: {
      measurements: {
        active: boolean;
      };
      snap: {
        active: boolean;
      };
      offset: {
        active: boolean;
        amount: number;
      };
    };
  };
  history: {
    initial: FeatureCollection<Polygon | LineString> | undefined;
    current: FeatureCollection<Polygon | LineString> | undefined;
    undoStack: FeatureCollection<Polygon | LineString>[];
    redoStack: FeatureCollection<Polygon | LineString>[];
  };
  staticFeatures: {
    [key: string]: Feature<LineString | Polygon>[];
  };
}

export type MapMode = MapState['toolbox']['mode'];
export type MapPlugin = keyof MapState['toolbox']['plugins'];

const initialState: MapState = {
  selectedFeatureIndexes: [],
  controls: {
    visibility: {
      contamination: false,
      hazards: false,
      heritage: false,
      ortsbild: false,
      trainNoiseDaytime: false,
      trainNoiseNighttime: false,
      carNoiseDaytime: false,
      carNoiseNighttime: false,
    },
    type: EMapType.ROADMAP,
    terrain: false,
  },
  toolbox: {
    mode: 'view',
    plugins: {
      measurements: {
        active: false,
      },
      snap: {
        active: false,
      },
      offset: {
        active: false,
        amount: 1,
      },
    },
  },
  history: {
    initial: undefined,
    current: undefined,
    undoStack: [],
    redoStack: [],
  },
  staticFeatures: {},
};

export const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    clear: () => initialState,
    setSelectedFeatureIndexes(state, action: PayloadAction<MapState['selectedFeatureIndexes']>) {
      state.selectedFeatureIndexes = action.payload.filter((index) => index >= 0);
    },
    toggleVisibilityControl(state, action: PayloadAction<EMapOverlay>) {
      state.controls.visibility[action.payload] = !state.controls.visibility[action.payload];
    },
    setVisibilityControl(state, action: PayloadAction<{ type: EMapOverlay; value: boolean }>) {
      state.controls.visibility[action.payload.type] = action.payload.value;
    },
    setMapType(state, action: PayloadAction<EMapType>) {
      state.controls.type = action.payload;
    },
    toggleMapType(state) {
      state.controls.type = state.controls.type === EMapType.ROADMAP ? EMapType.SATELLITE : EMapType.ROADMAP;
    },
    setMapTerrain(state, action: PayloadAction<boolean>) {
      state.controls.terrain = action.payload;
    },
    setMode(state, action: PayloadAction<MapMode>) {
      state.toolbox.mode = action.payload;
    },
    setInitial(state, action: PayloadAction<FeatureCollection<Polygon | LineString>>) {
      state.history.initial = action.payload;
      state.history.current = action.payload;
    },
    clearHistory(state) {
      state.history.current = state.history.initial;
      state.history.undoStack = [];
      state.history.redoStack = [];
    },
    undo(state) {
      if (state.history.undoStack.length > 0) {
        const undoState = cloneDeep(state.history.undoStack.pop());
        const current = state.history.current;
        if (undoState && current) {
          state.history.redoStack.push(current);
          state.history.current = undoState;
        }
      }
    },
    redo(state) {
      if (state.history.redoStack.length > 0) {
        const redoState = cloneDeep(state.history.redoStack.pop());
        const current = state.history.current;
        if (redoState && current) {
          state.history.undoStack.push(cloneDeep(current));
          state.history.current = redoState;
        }
      }
    },
    removeFeatureByIndex(state, action: PayloadAction<number>) {
      const index = action.payload;
      if (!state.history.current) return;
      mapSlice.caseReducers.commit(state, mapSlice.actions.commit(state.history.current));
      state.history.current.features.splice(index, 1);
    },
    setFeatureTypeById(state, action: PayloadAction<{ id: string; type: EMapLineType }>) {
      const { id, type } = action.payload;
      if (!state.history.current) return;
      mapSlice.caseReducers.commit(state, mapSlice.actions.commit(state.history.current));
      state.history.current.features = state.history.current.features.map((f) => {
        if (f.id === id || f.properties?.id === id) {
          return {
            ...f,
            properties: {
              ...f.properties,
              type,
            },
          };
        }
        return f;
      });

      if (!state.history.current) return;
    },
    setFeaturePropertyById(
      state,
      action: PayloadAction<{ id: string; property: string; value: string | number | boolean }>,
    ) {
      const { id, property, value } = action.payload;
      if (!state.history.current) return;
      state.history.current.features = state.history.current.features.map((f) => {
        if (f.id === id || f.properties?.id === id) {
          return {
            ...f,
            properties: {
              ...f.properties,
              [property]: value,
            },
          };
        }

        return f;
      });

      if (!state.history.current) return;
    },
    setStaticFeatures(state, action: PayloadAction<{ key: string; value: Feature<LineString | Polygon>[] }>) {
      state.staticFeatures[action.payload.key] = action.payload.value || [];
    },
    commit(state, action: PayloadAction<FeatureCollection<LineString | Polygon>>) {
      const current = state.history.current;
      if (!current) return;
      state.history.undoStack.push(cloneDeep(current));
      state.history.current = action.payload;
      state.history.redoStack = [];
    },
    togglePlugin(state, action: PayloadAction<MapPlugin>) {
      state.toolbox.plugins[action.payload].active = !state.toolbox.plugins[action.payload].active;
    },
    disableAllPlugins(state) {
      const pluginKeys = Object.keys(state.toolbox.plugins);
      for (const plugin of pluginKeys) {
        state.toolbox.plugins[plugin as MapPlugin].active = false;
      }
    },
    enablePlugins(state, action: PayloadAction<MapPlugin[]>) {
      for (const plugin of action.payload) {
        state.toolbox.plugins[plugin].active = true;
      }
    },
    disablePlugins(state, action: PayloadAction<MapPlugin[]>) {
      for (const plugin of action.payload) {
        Object.assign(state.toolbox.plugins[plugin], initialState.toolbox.plugins[plugin]);
      }
    },
    // TODO: types
    setPluginValues(state, action: PayloadAction<{ plugin: MapPlugin; values: Record<any, any> }>) {
      const { plugin, values } = action.payload;
      Object.assign(state.toolbox.plugins[plugin], values);
    },
  },
  selectors: {
    selectedFeatureIndexesSelector: (state) => state.selectedFeatureIndexes,
    toolboxModeSelector: (state) => state.toolbox.mode,
    projectMapFeaturesSelector: (state) => state.history.current,
    redoStackLengthSelector: (state) => state.history.redoStack.length,
    undoStackLengthSelector: (state) => state.history.undoStack.length,
    pluginSelector: (state) => state.toolbox.plugins,
    controlSelector: (state) => state.controls,
    visibilityControlSelector: (state) => state.controls.visibility,
    staticFeaturesSelector: (state) => state.staticFeatures,
  },
});

export const {
  setFeaturePropertyById,
  setSelectedFeatureIndexes,
  toggleVisibilityControl,
  setVisibilityControl,
  setMapType,
  setMapTerrain,
  removeFeatureByIndex,
  setFeatureTypeById,
  setStaticFeatures,
  disableAllPlugins,
  disablePlugins,
  enablePlugins,
  setPluginValues,
  setMode,
  setInitial,
  undo,
  redo,
  commit,
  clearHistory,
  togglePlugin,
} = mapSlice.actions;

export const {
  toolboxModeSelector,
  selectedFeatureIndexesSelector,
  pluginSelector,
  projectMapFeaturesSelector,
  redoStackLengthSelector,
  staticFeaturesSelector,
  undoStackLengthSelector,
  controlSelector,
  visibilityControlSelector,
} = mapSlice.selectors;
