import { alpha, Box, Button, Divider, Grid, InputAdornment, TextField, Theme, Typography } from '@mui/material';
import { GetShopResponse } from 'api/dtos/payment/shop/dto/response';
import { EProductID } from 'api/dtos/payment/shop/enums';
import FeasibilityStudyVideo from 'assets/videos/shop-product-feasibilityStudy.mp4';
import ProductCard from 'components/features/ShopModal/components/ProductCard';
import UNITS from 'constants/units';
import useModals from 'hooks/useModals';
import useLocationData from 'pages/App/hooks/useLocationData';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import { useDispatch } from 'react-redux';
import { useGetFeasibilityStudyPriceByAreaQuery } from 'state/services/backend/endpoints/shop/shop';
import { EModal } from 'types/enums/Modal';
import { useDebounce } from 'use-debounce';
import { formatNumber, getPrintValue } from 'utils/formatters';

const styles = {
  listItem: {
    position: 'relative',
    pl: 4,
    '&:before': {
      content: '"–"',
      position: 'absolute',
      top: -2,
      left: 0,
      color: 'grey.700',
    },
  },
  videoWrapper: {
    position: 'relative',
    display: 'flex',
    borderRadius: 1,
    overflow: 'hidden',
  },
  calculatorNumberField: {
    '& .MuiInputAdornment-root': {
      '& .MuiTypography-root': {
        fontSize: 14,
        color: 'grey.700',
      },
    },
    '& .MuiInputBase-root': {
      border: 1,
      borderRadius: 1,
      borderColor: 'grey.300',
      bgcolor: 'transparent !important',
      borderWidth: 1,
      transition: (theme: Theme) => theme.transitions.create('border-color'),
      '&:before, &:after': {
        content: 'none',
      },
      '&.Mui-focus, &:hover': {
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: 'grey.400',
        '& .MuiOutlinedInput-notchedOutline': {},
      },
    },
  },
  calculatorValueField: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    bgcolor: (theme: Theme) => alpha(theme.palette.grey[200], 0.5),
    borderRadius: 1,
    height: '100%',
    py: 1,
    px: 3,
  },
};

const FeasibilityStudyPricingCalculator = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { openModal } = useModals();

  const location = useLocationData();
  const { plot } = location ?? {};
  const [userArea, setUserArea] = useState<number>(plot?.area ?? 500);

  const [debouncedUserArea] = useDebounce(userArea, 250);

  const { data: price } = useGetFeasibilityStudyPriceByAreaQuery(debouncedUserArea);

  const handleOpenHelpModal = () => {
    dispatch(openModal(EModal.HELP));
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <NumericFormat
          customInput={TextField}
          label={t('project:plot.plotArea')}
          onValueChange={(values) => setUserArea(Number(values?.value))}
          value={formatNumber(userArea)}
          variant='filled'
          thousandSeparator=' '
          thousandsGroupStyle='thousand'
          allowNegative={false}
          isAllowed={(values) => {
            const { floatValue } = values;
            return floatValue === undefined || (floatValue >= 0 && floatValue <= 1000000);
          }}
          InputProps={{
            endAdornment: <InputAdornment position='end'>{UNITS.SQUAREMETER}</InputAdornment>,
          }}
          fullWidth
          size='small'
          sx={styles.calculatorNumberField}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box sx={styles.calculatorValueField}>
          {debouncedUserArea < 7500 && (
            <Typography variant='body2' color='textSecondary' sx={{ fontSize: 11 }}>
              {t('billing:general.price')} ({t('billing:general.excludingVAT')})
            </Typography>
          )}
          {debouncedUserArea < 7500 ? (
            <Typography variant='body1' fontWeight={500}>
              {getPrintValue(formatNumber(price ? price / 100 : null), UNITS.CURRENCY)}
            </Typography>
          ) : (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
              <Typography variant='body2' fontWeight={500} sx={{ pr: 3 }}>
                {t('shop:products.feasibilityStudy.pricingDetails.contactDescription')}
              </Typography>
              <Button variant='contained' color='tertiary' onClick={handleOpenHelpModal} size='small'>
                {t('shop:products.feasibilityStudy.pricingDetails.contactButton')}
              </Button>
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

const PRODUCT_ID = EProductID.FEASIBILITY_STUDY;

interface ProductFeasibilityStudyProps {
  shopData: GetShopResponse;
}

const ProductFeasibilityStudy = (props: ProductFeasibilityStudyProps) => {
  const { shopData } = props;
  const product = shopData[EProductID.FEASIBILITY_STUDY];

  const { t } = useTranslation();

  return (
    <ProductCard product={product} open toggleOpen={undefined} desktopOnly>
      <Box sx={{ p: 3 }}>
        <Grid container spacing={6}>
          <Grid item xs={12} md={6}>
            <Typography variant='body2' color='textSecondary' sx={{ mb: 3 }}>
              {t(`shop:products.${PRODUCT_ID}.description`)}
            </Typography>
            <Grid container spacing={3}>
              {['one', 'two', 'three', 'four', 'five', 'six'].map((reason) => (
                <Grid item xs={12} key={reason}>
                  <Box sx={styles.listItem}>
                    <Typography variant='body2' color='textSecondary'>
                      {t(`shop:products.${PRODUCT_ID}.reasons.${reason}`)}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={styles.videoWrapper}>
              <Box
                component='video'
                src={FeasibilityStudyVideo}
                muted
                autoPlay
                loop
                playsInline
                sx={{ width: '100%' }}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Typography variant='overline'>{t(`shop:products.${PRODUCT_ID}.pricingDetails.title`)}</Typography>
            <Typography variant='body2'>{t(`shop:products.${PRODUCT_ID}.pricingDetails.description`)}</Typography>
            <Box sx={{ mt: 3 }}>
              <FeasibilityStudyPricingCalculator />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </ProductCard>
  );
};

export default ProductFeasibilityStudy;
