import { generateUUID } from 'three/src/math/MathUtils';
import {
  EParkingLotLaneWidthLateral,
  EParkingLotLaneWidthLongitudinal,
  EParkingLotLayout,
  EParkingLotNrOfParkingRows,
  IParkingLotEntrance,
  TParkingLotOutdoor,
  XY,
} from '../../types';
import { getParkingLotCorners, getParkingLotEntrances } from './util';

export const OutdoorParkingConstants = {
  [EParkingLotLayout.LATERAL]: {
    [EParkingLotLaneWidthLateral.SIX_FIFTY]: {
      parkingSpotWidth: 2.5,
      parkingSpotDepth: 5,
      dw: 0.3,
    },
    [EParkingLotLaneWidthLateral.SIX_TWENTY_FIVE]: {
      parkingSpotWidth: 2.55,
      parkingSpotDepth: 5,
      dw: 0.3,
    },
    [EParkingLotLaneWidthLateral.SIX]: {
      parkingSpotWidth: 2.6,
      parkingSpotDepth: 5,
      dw: 0.3,
    },
    [EParkingLotLaneWidthLateral.FIVE_SEVENTY_FIVE]: {
      parkingSpotWidth: 2.65,
      parkingSpotDepth: 5,
      dw: 0.3,
    },
    [EParkingLotLaneWidthLateral.FIVE_FIFTY]: {
      parkingSpotWidth: 2.7,
      parkingSpotDepth: 5,
      dw: 0.3,
    },
    [EParkingLotLaneWidthLateral.FIVE_TWENTY_FIVE]: {
      parkingSpotWidth: 2.75,
      parkingSpotDepth: 5,
      dw: 0.3,
    },
    [EParkingLotLaneWidthLateral.FIVE]: {
      parkingSpotWidth: 2.8,
      parkingSpotDepth: 5,
      dw: 0.3,
    },
  },
  [EParkingLotLayout.LONGITUDINAL]: {
    [EParkingLotLaneWidthLongitudinal.THREE_FIFTY]: {
      parkingSpotWidth: 6,
      parkingSpotDepth: 1.9,
      dw: 1,
    },
    [EParkingLotLaneWidthLongitudinal.FOUR_SIXTY]: {
      parkingSpotWidth: 6,
      parkingSpotDepth: 1.9,
      dw: 1,
    },
  },
};

const getLaneMultiplier = (entranceLaneExists: boolean): number => {
  return entranceLaneExists ? 1 : 0;
};

export const getParkingSpotDepth = (
  layout: EParkingLotLayout,
  laneWidth: EParkingLotLaneWidthLongitudinal | EParkingLotLaneWidthLateral,
): number => {
  if (layout === EParkingLotLayout.LONGITUDINAL)
    return OutdoorParkingConstants[layout][laneWidth as EParkingLotLaneWidthLongitudinal].parkingSpotDepth;
  return OutdoorParkingConstants[layout][laneWidth as EParkingLotLaneWidthLateral].parkingSpotDepth;
};

const getTotalWidth = (
  layout: EParkingLotLayout,
  laneWidth: EParkingLotLaneWidthLongitudinal | EParkingLotLaneWidthLateral,
  nrOfParkingRows: number,
  entranceLaneExists: boolean,
): number => {
  return getParkingSpotDepth(layout, laneWidth) * nrOfParkingRows + laneWidth * getLaneMultiplier(entranceLaneExists);
};

export const getParkingSpotWidth = (
  layout: EParkingLotLayout,
  laneWidth: EParkingLotLaneWidthLateral | EParkingLotLaneWidthLongitudinal,
): number => {
  if (layout === EParkingLotLayout.LONGITUDINAL)
    return OutdoorParkingConstants[layout][laneWidth as EParkingLotLaneWidthLongitudinal].parkingSpotWidth;
  return OutdoorParkingConstants[layout][laneWidth as EParkingLotLaneWidthLateral].parkingSpotWidth;
};

export const getEdgeParkingIncrement = (
  layout: EParkingLotLayout,
  laneWidth: EParkingLotLaneWidthLateral | EParkingLotLaneWidthLongitudinal,
): number => {
  if (layout === EParkingLotLayout.LONGITUDINAL)
    return OutdoorParkingConstants[layout][laneWidth as EParkingLotLaneWidthLongitudinal].dw;
  return OutdoorParkingConstants[layout][laneWidth as EParkingLotLaneWidthLateral].dw;
};

const getFirstParkingRowWidth = (
  nrOfTotalParkingSpots: number,
  nrOfParkingRows: number,
  layout: EParkingLotLayout,
  laneWidth: EParkingLotLaneWidthLateral | EParkingLotLaneWidthLongitudinal,
): number => {
  const parkingSpotsPerRow = Math.ceil(nrOfTotalParkingSpots / nrOfParkingRows);
  const numberOfEdgeParkingIncrements = parkingSpotsPerRow === 1 ? 1 : 2;
  return (
    parkingSpotsPerRow * getParkingSpotWidth(layout, laneWidth) +
    numberOfEdgeParkingIncrements * getEdgeParkingIncrement(layout, laneWidth)
  );
};

const getSecondParkingRowWidth = (
  nrOfTotalParkingSpots: number,
  nrOfParkingRows: number,
  layout: EParkingLotLayout,
  laneWidth: EParkingLotLaneWidthLateral | EParkingLotLaneWidthLongitudinal,
): number => {
  if (nrOfParkingRows === 1) return 0;
  const parkingSpotsPerRow = Math.ceil(nrOfTotalParkingSpots / nrOfParkingRows);
  const a = nrOfParkingRows === 1 ? 0 : nrOfTotalParkingSpots - parkingSpotsPerRow;
  const b = nrOfTotalParkingSpots - parkingSpotsPerRow === 1 ? 1 : 2;
  return a * getParkingSpotWidth(layout, laneWidth) + b * getEdgeParkingIncrement(layout, laneWidth);
};

const getTotalLength = (
  nrOfTotalParkingSpots: number,
  nrOfParkingRows: number,
  layout: EParkingLotLayout,
  laneWidth: EParkingLotLaneWidthLateral | EParkingLotLaneWidthLongitudinal,
): number => {
  return Math.max(
    getFirstParkingRowWidth(nrOfTotalParkingSpots, nrOfParkingRows, layout, laneWidth),
    getSecondParkingRowWidth(nrOfTotalParkingSpots, nrOfParkingRows, layout, laneWidth),
  );
};

const getParkingLotEntrancesForTwoRows = (length: number): IParkingLotEntrance[] => {
  const halfLength = length / 2;
  return [
    {
      id: generateUUID(),
      position: [-halfLength, 0],
      entranceDirection: [1, 0],
    },
    {
      id: generateUUID(),
      position: [halfLength, 0],
      entranceDirection: [-1, 0],
    },
  ];
};

export const setOutdoorParkingCornersAndEntrances = (parkingLot: TParkingLotOutdoor): void => {
  const { nrOfTotalParkingSpots, nrOfParkingRows, layout, laneWidth, entranceLaneExists } = parkingLot.properties;
  const totalLength = getTotalLength(nrOfTotalParkingSpots, nrOfParkingRows, layout, laneWidth);
  const totalWidth = getTotalWidth(layout, laneWidth, nrOfParkingRows, entranceLaneExists);

  parkingLot.corners = getParkingLotCorners(totalLength, totalWidth);
  if (nrOfParkingRows === EParkingLotNrOfParkingRows.ONE) {
    parkingLot.entrances = getParkingLotEntrances(totalLength, totalWidth, laneWidth);
  } else {
    parkingLot.entrances = getParkingLotEntrancesForTwoRows(totalLength);
  }
};

export const getOutdoorParkingLotLines = (lot: TParkingLotOutdoor): [XY, XY][] => {
  const { corners, properties } = lot;
  const { laneWidth, layout, nrOfParkingRows, nrOfTotalParkingSpots, nrOfVisitorParkingSpots } = properties;
  const spotWidth = getParkingSpotWidth(layout, laneWidth);
  const spotDepth = getParkingSpotDepth(layout, laneWidth);
  const edgeBuffer = getEdgeParkingIncrement(layout, laneWidth);

  const xStart = corners[0][0];
  const yStart = corners[0][1];
  const yEnd = corners[1][1];

  const lines: [XY, XY][] = [];

  let spotsInARow = Math.ceil(nrOfTotalParkingSpots / nrOfParkingRows);

  let lineX = xStart;

  for (let i = 0; i <= spotsInARow; i++) {
    lines.push([
      [lineX, yStart],
      [lineX, yStart - spotDepth],
    ]);

    lineX += spotWidth;
    if (i === 0 || i === spotsInARow - 1) lineX += edgeBuffer;
  }

  lines.push([
    [xStart, yStart],
    [xStart + spotsInARow * spotWidth + 2 * edgeBuffer, yStart],
  ]);

  lines.push([
    [xStart, yStart - spotDepth],
    [xStart + spotsInARow * spotWidth + 2 * edgeBuffer, yStart - spotDepth],
  ]);

  lines.push([
    [xStart, yEnd],
    [xStart + spotsInARow * spotWidth + 2 * edgeBuffer, yEnd],
  ]);

  if (nrOfParkingRows === EParkingLotNrOfParkingRows.TWO) {
    if (nrOfTotalParkingSpots % 2 !== 0) {
      spotsInARow--;
    }

    lineX = xStart;

    for (let i = 0; i <= spotsInARow; i++) {
      lines.push([
        [lineX, yEnd],
        [lineX, yEnd + spotDepth],
      ]);

      lineX += spotWidth;
      if (i === 0) lineX += edgeBuffer;
      if (i === spotsInARow - 1) lineX += edgeBuffer;
    }

    lines.push([
      [xStart, yEnd + spotDepth],
      [xStart + spotsInARow * spotWidth + 2 * edgeBuffer, yEnd + spotDepth],
    ]);
  }

  return lines;
};
