import { skipToken } from '@reduxjs/toolkit/query';
import useExploreLocationData from 'pages/App/Explore/hooks/useExploreLocationData';
import { useSelector } from 'react-redux';
import { projectIdSelector } from 'state/selectors/router';
import { useGetProjectState } from 'state/services/backend/endpoints/project/project';
import { IProjectLocation } from 'types/project/project';
import { NumberParam, useQueryParams } from 'use-query-params';

const useLocationData = (): IProjectLocation | undefined => {
  const projectId = useSelector(projectIdSelector);

  const { data } = useGetProjectState(projectId ? { projectId } : skipToken);
  const { location: projectLocation } = data ?? {};

  const [{ lat, lng }] = useQueryParams({
    lng: NumberParam,
    lat: NumberParam,
  });
  const { location: exploreLocation } = useExploreLocationData({ lng, lat });

  return projectId ? projectLocation : exploreLocation;
};

export default useLocationData;
