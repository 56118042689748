import { proxyWithHistory } from 'valtio/utils';
import { TBuildings } from '../types';

interface IBuildingStoreState {
  buildings: TBuildings;
}

const buildingStore = proxyWithHistory<IBuildingStoreState>(
  {
    buildings: {},
  },
  false,
);

export const resetBuildingStoreHistory = () => {
  const lastItem = buildingStore.history.snapshots[buildingStore.history.index];

  buildingStore.history.wip = undefined;
  buildingStore.history.index = 0;
  buildingStore.history.snapshots = [lastItem];
};

export default buildingStore;
