export enum EMapLineType {
  ZONE_BORDER = 'zone_border',
  PLOT_BORDER = 'plot_border',
  CONSTRUCTION_LINE = 'construction_line',
  CONSTRUCTION_WINDOW = 'construction_window',
  FOREST_LINE = 'forest_line',
  WATER_LINE = 'water_line',
  DISTANCE_LINE = 'distance_line',
  HELP_LINE = 'helpline',
  ACCOUNTABLE_PLOT = 'accountable_plotarea',
  NOT_ACCOUNTABLE_PLOT = 'not_accountable_plotarea',
  BUILDING_FOOTPRINT = 'building_footprint',
}

export type XY = [number, number];
