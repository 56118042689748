import { XY } from './coordinates';

export enum EParkingLotType {
  FREEFORM_GARAGE = 'freeformGarage',
  GARAGE = 'garage',
  GARAGE_ROW = 'garageRow',
  OUTDOOR_PARKING = 'outdoorParking',
  UNDERGROUND_GARAGE = 'undergroundGarage',
}

export enum EParkingLotSize {
  S = 'S',
  M = 'M',
  L = 'L',
}

export enum EParkingLotEntrancePosition {
  SIDE = 'side',
  MIDDLE = 'middle',
  EDGE = 'edge',
}

export enum EParkingLotLayout {
  LATERAL = 'lateral',
  LONGITUDINAL = 'longitudinal',
}

export enum EParkingLotDistanceToSupport {
  ZERO = 0,
  POINT_EIGHTY = 0.8,
}

export enum EParkingLotLaneWidthLateral {
  FIVE = 5,
  FIVE_TWENTY_FIVE = 5.25,
  FIVE_FIFTY = 5.5,
  FIVE_SEVENTY_FIVE = 5.75,
  SIX = 6,
  SIX_TWENTY_FIVE = 6.25,
  SIX_FIFTY = 6.5,
}

export enum EParkingLotLaneWidthLongitudinal {
  THREE_FIFTY = 3.5,
  FOUR_SIXTY = 4.6,
}

export type TParkingLotLaneWidth = EParkingLotLaneWidthLateral | EParkingLotLaneWidthLongitudinal;

export enum EParkingLotNrOfGroups {
  ONE = 1,
  TWO = 2,
  THREE = 3,
}

export enum EParkingLotNrOfParkingRows {
  ONE = 1,
  TWO = 2,
}

export interface IParkingLotConstruction {
  externalWallThickness: number;
  foundationThickness: number;
  ceilingThickness: number;
}

export interface IUndergroundGarageParkingLotConstruction extends IParkingLotConstruction {
  supportColumnWidth: number;
  ceilingTechnicalClearance: number;
}

export interface IFreeformGarageParkingLotConstruction extends IParkingLotConstruction {
  ceilingTechnicalClearance: number;
}

export interface IParkingLotPosition {
  x: number;
  y: number;
  z: number;
}

export interface IUndergroundGarageParkingLotProperties {
  clearHeight: number;
  construction: IUndergroundGarageParkingLotConstruction;
  distanceToSupport: EParkingLotDistanceToSupport;
  entrancePosition: EParkingLotEntrancePosition;
  laneWidth: EParkingLotLaneWidthLateral;
  nrOfGroups: EParkingLotNrOfGroups;
  nrOfParkingRows: EParkingLotNrOfParkingRows;
  nrOfRegularParkingSpots: number;
  nrOfTotalParkingSpots: number;
  nrOfVisitorParkingSpots: number;
  type: EParkingLotType.UNDERGROUND_GARAGE;
}

export interface IGarageParkingLotProperties {
  clearHeight: number;
  construction: IParkingLotConstruction;
  nrOfRegularParkingSpots: number;
  nrOfTotalParkingSpots: number;
  nrOfVisitorParkingSpots: number;
  parkingSpotDepth: number;
  parkingSpotWidth: number;
  size: EParkingLotSize;
  storageAreaWidth: number;
  type: EParkingLotType.GARAGE;
}

export interface IGarageRowParkingLotProperties {
  clearHeight: number;
  construction: IParkingLotConstruction;
  nrOfRegularParkingSpots: number;
  nrOfTotalParkingSpots: number;
  nrOfVisitorParkingSpots: number;
  parkingSpotDepth: number;
  parkingSpotWidth: number;
  size: EParkingLotSize;
  storageAreaWidth: number;
  type: EParkingLotType.GARAGE_ROW;
}

export interface IOutdoorParkingLotProperties {
  entranceLaneExists: boolean;
  laneWidth: EParkingLotLaneWidthLateral | EParkingLotLaneWidthLongitudinal;
  layout: EParkingLotLayout;
  nrOfParkingRows: EParkingLotNrOfParkingRows;
  nrOfRegularParkingSpots: number;
  nrOfTotalParkingSpots: number;
  nrOfVisitorParkingSpots: number;
  type: EParkingLotType.OUTDOOR_PARKING;
}

export interface IFreeformGarageParkingLotProperties {
  clearHeight: number;
  construction: IFreeformGarageParkingLotConstruction;
  nrOfTotalParkingSpots: number;
  ratioOfVisitorParkingSpots: number;
  size: EParkingLotSize;
  technicalRoomArea: number;
  type: EParkingLotType.FREEFORM_GARAGE;
}

export type TParkingLotProperties =
  | IFreeformGarageParkingLotProperties
  | IGarageParkingLotProperties
  | IGarageRowParkingLotProperties
  | IOutdoorParkingLotProperties
  | IUndergroundGarageParkingLotProperties;

interface IParkingLot<T = TParkingLotProperties> {
  id: string;
  position: IParkingLotPosition;
  rotation: number;
  corners: XY[];
  entrances: IParkingLotEntrance[];
  properties: T;
}

export interface IParkingLotEntrance {
  id: string;
  position: XY;
  entranceDirection: XY;
}

export type TOutdoorParkingLot = IParkingLot<IOutdoorParkingLotProperties>;
export type TUndergroundGarageParkingLot = IParkingLot<IUndergroundGarageParkingLotProperties>;
export type TGarageParkingLot = IParkingLot<IGarageParkingLotProperties>;
export type TGarageRowParkingLot = IParkingLot<IGarageRowParkingLotProperties>;
export type TFreeformGarageParkingLot = IParkingLot<IFreeformGarageParkingLotProperties>;

export type TParkingLot =
  | TOutdoorParkingLot
  | TUndergroundGarageParkingLot
  | TGarageParkingLot
  | TGarageRowParkingLot
  | TFreeformGarageParkingLot;

export type TParkingLotOutdoor = TOutdoorParkingLot;

export type TParkingLotIndoor =
  | TUndergroundGarageParkingLot
  | TGarageParkingLot
  | TGarageRowParkingLot
  | TFreeformGarageParkingLot;

export type TParkingLots = Record<string, TParkingLot>;

// Roads and Pathways

export interface IRoad {
  id: string;
  path: XY[];
  width: ERoadWidth | EWalkwayWidth;
  surface: ERoadSurface;
  type: ERoadType;
}

// TODO: add correct values
export enum ERoadWidth {
  THREE = 3,
  FIVE = 5,
}

// TODO: add correct values
export enum EWalkwayWidth {
  ONE_HALF = 1.5,
  TWO = 2,
}

// TODO: add correct values
export enum ERoadSurface {
  ASPHALT = 'asphalt',
  CONCRETE = 'concrete',
  GRAVEL = 'gravel', // is considered part of green areas
}

export enum ERoadType {
  ROAD = 'road',
  WALKWAY = 'walkway',
}
