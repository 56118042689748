enum RequestMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

type QueryParameters = Record<string, string | string[] | number | number[] | boolean | undefined>;

export { RequestMethod };
export type { QueryParameters };
