import { zodResolver } from '@hookform/resolvers/zod/dist/zod';
import { Box, Button, Grid, Typography } from '@mui/material';
import ModalContentWrapper from 'components/display/ModalContentWrapper';
import SuccessIcon from 'components/display/SuccessIcon';
import FieldErrorMessage from 'components/inputs/FieldErrorMessage';
import LoadingWrapper from 'components/utility/LoadingWrapper';
import i18n from 'i18n';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { userSelector } from 'state/selectors/user';
import { useRequestHelpMutation } from 'state/services/backend/endpoints/request/request';
import LargeField from 'theme/components/inputs/LargeField';
import * as z from 'zod';

interface IFormData {
  fname: string;
  lname: string;
  email: string;
  phone: string;
  comment: string;
}

export const helpSchema = z.object({
  fname: z.string().min(1, i18n.t('forms:fields.firstName.required')),
  lname: z.string().min(1, i18n.t('forms:fields.lastName.required')),
  email: z.string().min(1, i18n.t('forms:fields.email.required')),
  phone: z.string().min(1, i18n.t('forms:fields.phone.required')),
  comment: z.string().min(1, i18n.t('modals:help.field.required')),
});

const HelpModalContent = () => {
  const { t } = useTranslation();

  const user = useSelector(userSelector);

  const [requestHelp, { isLoading, isSuccess }] = useRequestHelpMutation();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<IFormData>({
    defaultValues: {
      fname: user.firstName,
      lname: user.lastName,
      email: user.email,
      phone: user.phone,
      comment: '',
    },
    resolver: zodResolver(helpSchema),
  });

  const onSubmitHandler = (data: IFormData) => {
    requestHelp({
      firstName: data.fname,
      lastName: data.lname,
      email: data.email,
      phone: data.phone,
      comment: data.comment,
    });
  };

  return (
    <ModalContentWrapper maxWidth={450} sx={{ p: 9 }}>
      {isSuccess ? (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 6 }}>
            <SuccessIcon />
          </Box>
          <Typography variant='h5' component='h2' textAlign='center' fontWeight={500} sx={{ mb: 3 }}>
            {t('modals:help.requestSent.title')}
          </Typography>
          <Typography variant='body1' textAlign='center'>
            {t('modals:help.requestSent.description')}
          </Typography>
        </>
      ) : (
        <LoadingWrapper loading={isLoading}>
          <Box sx={{ mb: 3 }}>
            <Typography variant='h5' component='h2' textAlign='center' fontWeight={500}>
              {t('modals:help.title')}
            </Typography>
          </Box>
          <Box sx={{ mb: 8, textAlign: 'center' }}>
            <Typography variant='body1'>{t('modals:help.description')}</Typography>
          </Box>
          <form onSubmit={handleSubmit(onSubmitHandler)}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <LargeField
                  {...register('fname')}
                  label={t('forms:fields.firstName.label')}
                  type='text'
                  fullWidth
                  error={!!errors?.fname}
                  required
                  multiline
                />
                <FieldErrorMessage errors={errors} name='fname' sx={{ mt: 1 }} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <LargeField
                  {...register('lname')}
                  label={t('forms:fields.lastName.label')}
                  type='text'
                  fullWidth
                  error={!!errors?.lname}
                  required
                  multiline
                />
                <FieldErrorMessage errors={errors} name='lname' sx={{ mt: 1 }} />
              </Grid>
              <Grid item xs={12}>
                <LargeField
                  {...register('email')}
                  label={t('forms:fields.email.label')}
                  type='email'
                  fullWidth
                  error={!!errors?.comment}
                  required
                  multiline
                />
                <FieldErrorMessage errors={errors} name='email' sx={{ mt: 1 }} />
              </Grid>
              <Grid item xs={12}>
                <LargeField
                  {...register('phone')}
                  label={t('forms:fields.phone.label')}
                  type='phone'
                  fullWidth
                  error={!!errors?.phone}
                  required
                  multiline
                />
                <FieldErrorMessage errors={errors} name='phone' sx={{ mt: 1 }} />
              </Grid>
              <Grid item xs={12}>
                <LargeField
                  {...register('comment')}
                  label={t('modals:help.field.label')}
                  type='text'
                  fullWidth
                  error={!!errors?.comment}
                  required
                  multiline
                />
                <FieldErrorMessage errors={errors} name='comment' sx={{ mt: 1 }} />
              </Grid>
            </Grid>
            <Box mt={5}>
              <Button type='submit' variant='contained' color='secondary' fullWidth>
                {t('modals:help.buttonText')}
              </Button>
            </Box>
          </form>
        </LoadingWrapper>
      )}
    </ModalContentWrapper>
  );
};

export default HelpModalContent;
