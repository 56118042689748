import { XYZ } from './coordinates';
import {
  EApplicationMode,
  EApplicationStep,
  EApplicationTool,
  EApplicationTopic,
  ESelectionFilter,
} from '../store/store';

export type TOnlyOnePathPart =
  | ({ topic?: undefined; mode?: undefined; tool?: undefined; selectionFilter?: undefined } & {
      step: EApplicationStep;
    })
  | ({ step?: undefined; mode?: undefined; tool?: undefined; selectionFilter?: undefined } & {
      topic: EApplicationTopic;
    })
  | ({ step?: undefined; topic?: undefined; tool?: undefined; selectionFilter?: undefined } & {
      mode: EApplicationMode;
    })
  | ({ step?: undefined; topic?: undefined; mode?: undefined; selectionFilter?: undefined } & {
      tool: EApplicationTool;
    })
  | ({ step?: undefined; topic?: undefined; mode?: undefined; tool?: undefined } & {
      selectionFilter: ESelectionFilter;
    });

export type TContinuousPathChain =
  | {
      step: EApplicationStep;
      topic: EApplicationTopic;
      mode?: undefined;
      tool?: undefined;
      selectionFilter?: undefined;
    }
  | {
      step: EApplicationStep;
      topic: EApplicationTopic;
      mode: EApplicationMode;
      tool?: undefined;
      selectionFilter?: undefined;
    }
  | {
      step: EApplicationStep;
      topic: EApplicationTopic;
      mode: EApplicationMode;
      tool: EApplicationTool;
      selectionFilter?: undefined;
    }
  | {
      step: EApplicationStep;
      topic: EApplicationTopic;
      mode: EApplicationMode;
      tool: EApplicationTool;
      selectionFilter: ESelectionFilter;
    };

export enum EInteractionType {
  CLICK = 'click',
  HOVER = 'hover',
}

export enum ETerrainWidth {
  SMALL = 200,
  LARGE = 500,
}

export enum ETerrainImageType {
  SATELLITE = 'satellite',
  ROADMAP = 'roadmap',
  CADASTRE = 'cadastre',
}

export type TTerrain = XYZ[][];

export interface ITerrainProperties {
  id: string;
  highestPoint: XYZ;
  lowestPoint: XYZ;
  midpointElevation: number;
  terrainWidth: number;
}

export interface ITerrainWithProperties {
  terrain: TTerrain;
  properties: ITerrainProperties;
}

export type ITreeHeightScales = Record<string, [number, number, number]>;
