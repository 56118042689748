import { SvgIcon, SvgIconProps } from '@mui/material';

const WarningCircle = (props: SvgIconProps) => (
  <SvgIcon width='100' height='100' viewBox='0 0 100 100' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path d='M50 100C77.6142 100 100 77.6142 100 50C100 22.3858 77.6142 0 50 0C22.3858 0 0 22.3858 0 50C0 77.6142 22.3858 100 50 100Z' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M50.0002 18.001C52.9456 18.001 55.3333 20.3887 55.3333 23.3341V60.3132C55.3333 63.2586 52.9456 65.6463 50.0002 65.6463C47.0547 65.6463 44.667 63.2586 44.667 60.3132V23.3341C44.667 20.3887 47.0547 18.001 50.0002 18.001Z'
      fill='#FBFBFB'
    />
    <path
      d='M50.0005 82C52.9461 82 55.334 79.6117 55.334 76.6661C55.334 73.7205 52.9461 71.3326 50.0005 71.3326C47.0549 71.3326 44.667 73.7205 44.667 76.6661C44.667 79.6117 47.0549 82 50.0005 82Z'
      fill='#FBFBFB'
    />
  </SvgIcon>
);

export default WarningCircle;
