import { SvgIcon, SvgIconProps } from '@mui/material';

const FileRows = (props: SvgIconProps) => (
  <SvgIcon width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.5 3C4.5 2.72386 4.72386 2.5 5 2.5H14.3333C14.4659 2.5 14.5931 2.55268 14.6869 2.64645L19.3536 7.31311C19.4473 7.40688 19.5 7.53406 19.5 7.66667V21C19.5 21.2761 19.2761 21.5 19 21.5H5C4.72386 21.5 4.5 21.2761 4.5 21V3ZM5.5 3.5V20.5H18.5V7.87377L14.1262 3.5H5.5Z'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.3333 2.5C14.6095 2.5 14.8333 2.72386 14.8333 3V7.16667H19C19.2762 7.16667 19.5 7.39052 19.5 7.66667C19.5 7.94281 19.2762 8.16667 19 8.16667H14.3333C14.0572 8.16667 13.8333 7.94281 13.8333 7.66667V3C13.8333 2.72386 14.0572 2.5 14.3333 2.5Z'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.47607 11C7.47607 10.7239 7.69993 10.5 7.97607 10.5H9.47723C9.75338 10.5 9.97723 10.7239 9.97723 11C9.97723 11.2761 9.75338 11.5 9.47723 11.5H7.97607C7.69993 11.5 7.47607 11.2761 7.47607 11ZM16.0239 11.5L11.2434 11.5C10.9672 11.5 10.7434 11.2761 10.7434 11C10.7434 10.7239 10.9672 10.5 11.2434 10.5L16.0239 10.5C16.3001 10.5 16.5239 10.7239 16.5239 11C16.5239 11.2761 16.3001 11.5 16.0239 11.5ZM16.0239 14.5L11.2434 14.5C10.9672 14.5 10.7434 14.2761 10.7434 14C10.7434 13.7239 10.9672 13.5 11.2434 13.5L16.0239 13.5C16.3001 13.5 16.5239 13.7239 16.5239 14C16.5239 14.2761 16.3001 14.5 16.0239 14.5ZM7.47607 14C7.47607 13.7239 7.69993 13.5 7.97607 13.5H9.47723C9.75338 13.5 9.97723 13.7239 9.97723 14C9.97723 14.2761 9.75338 14.5 9.47723 14.5H7.97607C7.69993 14.5 7.47607 14.2761 7.47607 14ZM16.0239 17.5L11.2434 17.5C10.9672 17.5 10.7434 17.2761 10.7434 17C10.7434 16.7239 10.9672 16.5 11.2434 16.5L16.0239 16.5C16.3001 16.5 16.5239 16.7239 16.5239 17C16.5239 17.2761 16.3001 17.5 16.0239 17.5ZM7.47607 17C7.47607 16.7239 7.69993 16.5 7.97607 16.5H9.47723C9.75338 16.5 9.97723 16.7239 9.97723 17C9.97723 17.2761 9.75338 17.5 9.47723 17.5H7.97607C7.69993 17.5 7.47607 17.2761 7.47607 17Z'
    />
  </SvgIcon>
);

export default FileRows;
