import { CssBaseline, ThemeProvider } from '@mui/material';
import React from 'react';
import { MapProvider } from 'react-map-gl/maplibre';
import { Provider as ReduxProvider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import appRouter from 'routes/routers/app/appRouter';
import 'maplibre-gl/dist/maplibre-gl.css';
import store from 'state/store';
import theme from 'theme';

const App = () => {
  return (
    <MapProvider>
      <ReduxProvider store={store}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <RouterProvider router={appRouter} />
        </ThemeProvider>
      </ReduxProvider>
    </MapProvider>
  );
};

export default App;
