import { zodResolver } from '@hookform/resolvers/zod/dist/zod';
import { Box, Button, Grid, Typography } from '@mui/material';
import { ERequestSomethingType } from 'api/dtos/request/request';
import ModalContentWrapper from 'components/display/ModalContentWrapper';
import SuccessIcon from 'components/display/SuccessIcon';
import LoadingWrapper from 'components/utility/LoadingWrapper';
import useUser from 'hooks/useUser';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useRequestSomethingMutation } from 'state/services/backend/endpoints/request/request';
import LargeField from 'theme/components/inputs/LargeField';
import * as z from 'zod';

interface IFormData {
  fullname: string;
  email: string;
}

export const demoFormSchema = z.object({
  fullname: z.string(),
  email: z.string().email(),
});

const DemoModalContent = () => {
  const { t } = useTranslation();

  const { user } = useUser();

  const [requestSomething, { isLoading, isSuccess }] = useRequestSomethingMutation();

  const { register, handleSubmit } = useForm<IFormData>({
    defaultValues: {
      fullname: [user?.firstName, user?.lastName].filter(Boolean).join(' '),
      email: user?.email,
    },
    resolver: zodResolver(demoFormSchema),
  });

  const onHandleSubmit = async (data: IFormData) => {
    if (!user) return;

    requestSomething({
      fullname: data.fullname,
      phone: user.phone,
      company: user.company,
      email: data.email,
      newsletterAgree: true,
      type: ERequestSomethingType.DEMO,
    });
  };

  return (
    <ModalContentWrapper maxWidth={450} sx={{ p: 9 }}>
      {isSuccess ? (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 6 }}>
            <SuccessIcon />
          </Box>
          <Typography variant='h5' component='h2' textAlign='center' fontWeight={500} sx={{ mb: 3 }}>
            {t('modals:demo.requestSent.title')}
          </Typography>
          <Typography variant='body1' textAlign='center'>
            {t('modals:demo.requestSent.description')}
          </Typography>
        </>
      ) : (
        <LoadingWrapper loading={isLoading}>
          <Box sx={{ mb: 3 }}>
            <Typography variant='h5' component='h2' textAlign='center' fontWeight={500}>
              {t('modals:demo.title')}
            </Typography>
          </Box>
          <Box sx={{ mb: 8, textAlign: 'center' }}>
            <Typography variant='body1'>{t('modals:demo.description')}</Typography>
          </Box>
          <form onSubmit={handleSubmit(onHandleSubmit)}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <LargeField
                  {...register('email')}
                  label={t('modals:demo.email.label')}
                  type='text'
                  fullWidth
                  multiline
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <LargeField
                  {...register('fullname')}
                  label={t('modals:demo.fullname.label')}
                  type='text'
                  fullWidth
                  multiline
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ mt: 6, textAlign: 'center' }}>
                  <Button type='submit' variant='contained' color='secondary' size='large' disabled={isLoading}>
                    {t('modals:demo.buttonText')}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        </LoadingWrapper>
      )}
    </ModalContentWrapper>
  );
};

export default DemoModalContent;
