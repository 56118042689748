import FahrlanderModal from 'components/features/Integrations/Partners/Fahrlander/FahrlanderModal';
import ShopModal from 'components/features/ShopModal';
import CalendlyModal from 'components/modals/CalendlyModal';
import DataOverviewModal from 'components/modals/DataOverviewModal';
import DemoModal from 'components/modals/DemoModal';
import HelpModal from 'components/modals/HelpModal';
import SubscriptionModal from 'components/modals/SubscriptionModal';
import useLocationData from 'pages/App/hooks/useLocationData';
import React from 'react';

const Modals = () => {
  const location = useLocationData();

  return (
    <>
      <CalendlyModal />
      <DemoModal />
      <FahrlanderModal />
      <HelpModal />
      <SubscriptionModal />
      {location && <ShopModal />}
      <DataOverviewModal />
    </>
  );
};

export default Modals;
