import { zodResolver } from '@hookform/resolvers/zod/dist/zod';
import { KeyboardArrowLeft } from '@mui/icons-material';
import { Box, Button, Checkbox, FormControlLabel, Grid, Link as MuiLink, Typography } from '@mui/material';
import ModalContentWrapper from 'components/display/ModalContentWrapper';
import FieldErrorMessage from 'components/inputs/FieldErrorMessage';
import useUser from 'hooks/useUser';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FULL_SHOP_PATHS } from 'routes/routers/shop/shopPaths';
import { shopBillingDetailsSelector } from 'state/selectors/shop';
import { IBillingDetails, setShopBillingDetails } from 'state/slices/shop';
import LargeField from 'theme/components/inputs/LargeField';
import TextIconButton from 'theme/components/inputs/TextIconButton';
import { billingDetailsSchema } from 'utils/schemas/billingDetails';

interface IFormData {
  email: string;
  fname: string;
  lname: string;
  company: string;
  streetNr: string;
  zip: string;
  city: string;
  agree: boolean;
}

const BillingDetailsForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { user } = useUser();
  const billingDetails = useSelector(shopBillingDetailsSelector);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormData>({
    defaultValues: {
      email: billingDetails?.email || user?.email || '',
      fname: billingDetails?.fname || user?.firstName || '',
      lname: billingDetails?.lname || user?.lastName || '',
      company: billingDetails?.company || user?.company || '',
      streetNr: billingDetails?.address || user?.street || '',
      zip: billingDetails?.postal || user?.postcode || '',
      city: billingDetails?.city || user?.city || '',
    },
    resolver: zodResolver(billingDetailsSchema),
  });

  const onSubmitHandler = (data: IFormData) => {
    const billingDetails: IBillingDetails = {
      email: data.email,
      fname: data.fname,
      lname: data.lname,
      company: data.company,
      address: data.streetNr,
      postal: data.zip,
      city: data.city,
    };

    dispatch(setShopBillingDetails(billingDetails));

    navigate(FULL_SHOP_PATHS.CHECKOUT.ROOT);
  };

  const handleBackNavigate = () => {
    navigate(FULL_SHOP_PATHS.SHOP.ROOT);
  };

  return (
    <ModalContentWrapper maxWidth={500} sx={{ p: 6 }}>
      <Box sx={{ mb: 6 }}>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <TextIconButton Icon={<KeyboardArrowLeft />} onClick={handleBackNavigate} />
          </Grid>
          <Grid item xs={6}>
            <Typography variant='h5' component='h2' fontWeight={500} textAlign='center'>
              {t('shop:views.billingDetails.title')}
            </Typography>
          </Grid>
          <Grid item xs={3} />
        </Grid>
      </Box>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <LargeField
              {...register('email', { required: t('forms:fields.email.required') })}
              label={t('forms:fields.email.label')}
              type='email'
              fullWidth
              required
              error={!!errors.email}
            />
            <FieldErrorMessage errors={errors} name='email' sx={{ mt: 1 }} />
          </Grid>
          <Grid item xs={6}>
            <LargeField
              {...register('fname', { required: t('forms:fields.firstName.required') })}
              label={t('forms:fields.firstName.label')}
              type='text'
              fullWidth
              required
              error={!!errors.fname}
            />
            <FieldErrorMessage errors={errors} name='fname' sx={{ mt: 1 }} />
          </Grid>
          <Grid item xs={6}>
            <LargeField
              {...register('lname', { required: t('forms:fields.lastName.required') })}
              label={t('forms:fields.lastName.label')}
              type='text'
              fullWidth
              required
              error={!!errors.lname}
            />
            <FieldErrorMessage errors={errors} name='lname' sx={{ mt: 1 }} />
          </Grid>
          <Grid item xs={12}>
            <LargeField
              {...register('company')}
              label={t('forms:fields.company.label')}
              type='text'
              fullWidth
              error={!!errors.company}
            />
            <FieldErrorMessage errors={errors} name='company' sx={{ mt: 1 }} />
          </Grid>
          <Grid item xs={12}>
            <LargeField
              {...register('streetNr')}
              label={t('forms:fields.streetNr.label')}
              type='text'
              fullWidth
              required
              error={!!errors.streetNr}
            />
            <FieldErrorMessage errors={errors} name='phone' sx={{ mt: 1 }} />
          </Grid>
          <Grid item xs={4}>
            <LargeField
              {...register('zip', { required: t('forms:fields.zip.required') })}
              label={t('forms:fields.zip.label')}
              type='text'
              fullWidth
              required
              error={!!errors.zip}
            />
            <FieldErrorMessage errors={errors} name='fname' sx={{ mt: 1 }} />
          </Grid>
          <Grid item xs={8}>
            <LargeField
              {...register('city', { required: t('forms:fields.city.required') })}
              label={t('forms:fields.city.label')}
              type='text'
              fullWidth
              required
              error={!!errors.city}
            />
            <FieldErrorMessage errors={errors} name='lname' sx={{ mt: 1 }} />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              {...register('agree')}
              control={<Checkbox color='secondary' />}
              sx={{
                '& .MuiFormControlLabel-label': {
                  typography: 'body2',
                },
              }}
              label={
                <MuiLink href={t('forms:fields.termsConditions.link')} target='_blank'>
                  {t('forms:fields.termsConditions.label')} *
                </MuiLink>
              }
            />
            <FieldErrorMessage errors={errors} name='agree' sx={{ mt: 1 }} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body2' color='textSecondary'>
              {t('forms:messages.fieldsMarkedWithAsteriskRequired')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ textAlign: 'center', mt: 3 }}>
              <Button type='submit' variant='contained' color='secondary' size='large'>
                {t('general:continue')}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </ModalContentWrapper>
  );
};

export default BillingDetailsForm;
