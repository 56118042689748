import { CssBaseline, ThemeProvider } from '@mui/material';
import * as Sentry from '@sentry/react';
import BaseLayoutError from 'components/layouts/BaseLayout/BaseLayoutError';
import Modals from 'components/modals/Modals';
import Authenticator from 'components/utility/Authenticator';
import CookieBanner from 'components/utility/CookieBanner';
import CypressHelper from 'components/utility/CypressHelper';
import Datatrans from 'components/utility/Datatrans';
import GoogleAnalyzer from 'components/utility/GoogleAnalyzer';
import LanguageDetector from 'components/utility/LanguageDetector';
import Toaster from 'components/utility/Toaster';
import useUser from 'hooks/useUser';
import DeviceRestriction from 'pages/DeviceRestriction';
import React, { useEffect } from 'react';
import { Provider as ReduxProvider } from 'react-redux/es/exports';
import { Outlet } from 'react-router-dom';
import store from 'state/store';
import theme from 'theme';
import SiteHelmet from 'theme/components/utility/SiteHelmet';
import useDevice from 'theme/hooks/useDevice';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

const SentryContextConnector = () => {
  const { user } = useUser();

  useEffect(() => {
    Sentry.setUser(
      user
        ? {
            email: user.email,
          }
        : null,
    );

    return () => Sentry.setUser(null);
  }, [user]);

  return null;
};

const AuthenticatedContent = () => (
  <>
    <Modals />
    <Datatrans />
  </>
);

const Content = () => (
  <>
    <LanguageDetector />
    <Outlet />
  </>
);

const handleResetError = () => window.location.reload();

const AppWrapper = () => {
  const { isMobileLandscape } = useDevice();

  return (
    <QueryParamProvider adapter={ReactRouter6Adapter}>
      <ReduxProvider store={store}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Sentry.ErrorBoundary fallback={() => <BaseLayoutError resetError={handleResetError} />}>
            <SiteHelmet />
            {window.Cypress && <CypressHelper />}
            <SentryContextConnector />
            <GoogleAnalyzer />
            <Authenticator authenticatedContent={<AuthenticatedContent />} content={<Content />} />
            {isMobileLandscape && <DeviceRestriction />}
            <CookieBanner />
            <Toaster />
          </Sentry.ErrorBoundary>
        </ThemeProvider>
      </ReduxProvider>
    </QueryParamProvider>
  );
};

export default AppWrapper;
